form{
    input,
    select,
    textarea,
    button{
        border: 1px solid $gris4;
        padding: 5px;
        font-size: 1.5rem;
        font-family: $asap;
        color: $gris;
        appearance: none;
        border-radius: 0;
        height: 30px;

        &[type="submit"]{
            background-color: $bleu;
            border-color: $bleu;
            color: $blanc;
            width: auto;
            text-transform: uppercase;
            padding-right: 15px;
            padding-left: 15px;
            transition: 0.3s;

            &:hover{
                background-color: $bleu2;
                border-color: $bleu2;
            }
        }
        &.error{
            border-color: red !important;
        }
    }
    button{
        text-transform: uppercase;
        background-color: $blanc;
        padding-right: 15px;
        padding-left: 15px;
        cursor: pointer;
        transition: 0.3s;

        &:hover{
            background-color: $bleu2;
            color: $blanc;
            border-color: $bleu2;
        }
    }
    select{
        font-size: 1.4rem;
        appearance: none;
        padding-right: 30px;
        background : url('../images/fleche-select.png') no-repeat right center $blanc;
    }
    select::-ms-expand {
        display: none;
    }
    .lt-ie10 select{
        background-image: none;

        &::-ms-expand {
            display: inline;
        }
    }
    .required-field{
        font-size: 1.3rem;
    }
}

.formulaire-contact{
    input{
        margin-bottom: 3px;
        border:1px solid $blanc;
    }
    textarea{
        border-color: $blanc;
    }
}

.message-container{
    .error-container{
        font-size:1.6rem;
        //color: red;

        p{
            margin-bottom: 0;
        }
    }
    label{
        display: block;
    }
}
.messages--error{
    font-size: 1.6rem;
    color: $blanc;
    font-weight: 700;

    ul{
        //margin-left: 19px;

        li{
            list-style-type: none;
        }
    }
}


.bg-bleu,
.node-type-contact .formulaire-contact,
.prise-rendez-vous-invest,
.node-type-espace-investisseur .action-choices{
    .messages--error,
    .message-container{
        //color: #fd482a;
        color: #ffffff;

        p{
            //color: #e9c300;
            color: #ffffff;
        }
    }
    form{
        input,
        select,
        textarea,
        button{

            &.error{
                border-color: #e9c300 !important;
            }
        }
    }
}
.bg-jaune{
    .messages--error,
    .message-container{
        //color: #8f4579;
        color: #ffffff;

        p{
            //color: #8f4579;
            color: #ffffff;
        }
    }
    form{
        input,
        select,
        textarea,
        button{

            &.error{
                border-color: #8f4579 !important;
            }
        }
    }
}

.form-no-result-bloc1,
.action-choices,
.action-choices-bien-vendu{
    .messages--error,
    .message-container{
        color: #fd482a;

        p{
            color: #fd482a;
        }
    }
    form{
        input,
        select,
        textarea,
        button{

            &.error{
                border-color: #fd482a !important;
            }
        }
    }
}

.webform-confirmation{
    p{
        //color: #ffffff;
        color: $grisf;
    }
}

.js-error-captcha {
    color: #fd482a;
}
