.form-realty-search {
    display: table;
}

.fieldset-localisation {
    background: #ffffff;
    border: 0;
    width: 164px;
}
.fieldset-localisation .localisation-list {
    list-style: none;
}

.fieldset-localisation .localisation-list .choice-item {
    background: #dddddd;
    padding: 3px 5px;
    border: 1px solid #999999;
}

.fieldset-localisation .localisation-list .choice-item .localisation-delete {
    border: 0;
    cursor: pointer;
    background: none;
    outline: none;
}

.form-realty-search .input-box,
.form-realty-search .separator-box,
.form-realty-search .buttons {
    display: inline-block;
}

.form-realty-search .separator-box { color: #ffffff; }

.form-realty-search .input-box .input-ville { width: 164px; }
.form-realty-search .input-box .select-type-bien { width: 154px; }
.form-realty-search .input-box .select-sale-type { width: 164px; }
.form-realty-search .input-box .select-nb-pieces { width: 66px; }
.form-realty-search .input-box .input-surface-habitable { width: 107px; }
.form-realty-search .input-box .input-prix { width: 97px; }
.form-realty-search .input-box .input-mensualite { width: 130px; }

.sub-filters {
    display: none;
}

/* autocomplete */
.ui-autocomplete {
    position: absolute;
    top: 0;
    left: 0;
    cursor: default;
    width: 164px;
    z-index: 100;
}

.ui-widget-content {
    background: #ffffff;
    color: #222222;
}

.ui-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    outline: none;
}
.ui-menu .ui-menu-item {
    position: relative;
    margin: 0;
    padding: 3px .4em 3px .4em;
    cursor: pointer;
    min-height: 0;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
    background: #dddddd;
}
.ui-menu .ui-menu-item .cp {
    float: right;
}

/* items */
.loader-mouse {
    background: url('../images/loader.gif') top left /contain no-repeat;
    width: 24px;
    height: 24px;
    display: none;
    z-index: 101;
}
.loader {
    display: none;
    background: url('../images/loader.gif') top left no-repeat;
    width: 75px;
    height: 75px;
    margin: auto;
}
.item.clone {
    display: none;
}
.item.item-third {
    margin-right: 0;
}

.form-no-result {
    display: none;
}

.container-checkbox {
    display: table;
}

/* schedule */
.schedule .title {
    display: table;
    width: 100%;
}
.schedule .title p {
    float: left;
}
.schedule .title p + p {
    float: right;
}
.schedule .bloc-biens {
    background: #01abc5;
    padding: 20px 0;
    display: table;
    width: 100%;
}
.schedule .bloc-biens .left {
    float: left;
}
.schedule .bloc-biens .right {
    float: right;
}
.schedule .bloc-biens .liste-visite-biens li {
    background: #ffffff;
}
.schedule .form {
    background: #edece8;
}
.schedule .form .bloc-input,
.schedule .form .bloc-textarea {
    float: left;
}
.schedule .form .buttons {
    clear: left;
}

.schedule.schedule-calc-investissor .form {
    padding-top: 40px;
}
.schedule.schedule-calc-investissor .form .intro {
    margin-bottom: 8px;
}

.schedule.schedule-calc-investissor .rappel_chart {
    display: table;
    margin-top: 20px;
}

.schedule.schedule-calc-investissor .rappel_chart .infos {
    float: left;
    margin-right: 20px;
    margin-top: 20px;
    width: 450px;
}

.schedule.schedule-calc-investissor .rappel_chart .infos p {
    display: table;
    width: 100%;
    margin-bottom: 5px;
}

.schedule.schedule-calc-investissor .rappel_chart .infos p span.label {
    display: inline-block;
    width: 78%;
    float: left;
    margin-right: 1%;
    padding: 7px 10px 8px;
    background: #8F4579;
    color: #ffffff;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.schedule.schedule-calc-investissor .rappel_chart .infos p span.prix {
    float: right;
    width: 20%;
    background: #ffffff;
    color: #8F4579;
    padding: 7px 10px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.schedule.schedule-calc-investissor .rappel_chart .img_chart {
    float: right;
    margin-top: 30px;
}
