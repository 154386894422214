@import '_variables';
@import '_normalize';
@import '_form';
@import '_commun';
@import '_realty';


body{
    //overflow-x: hidden;
    //background-color: red;
}
.messages{
    &.messages--warning {
        display: none;
    }
}
.debug-site{
    position: fixed;
    z-index: 10000000000000000000000;
    top: 0;
    right: 0;
    background-color: red;
    color: $blanc;
    padding: 5px;
}

.printDisplay{
    display: none;
}
.cookies{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 100000;

    .bouton{
        background-color: $blanc;
        display: inline-block;
        padding: 5px;
        color: rgba($bleu2, 0.7);
        transition: 0.3s;
        margin: 0 10px;

        &:hover{
            background-color: $jaune;
            color: $blanc;
        }
    }
    p{
        display: inline-block;
        width: 100%;
        color: $blanc;
        padding: 10px 10px;
        background-color: rgba($bleu2, 0.7);
        transition: 0.3s;
    }
    .underline{
        text-decoration: underline;
    }
}
.site{
    padding-top: 156px;
    overflow: hidden;
}
.wrap{
    max-width: $wrap;
    width: 100%;
    margin: 0 auto;
}

// .messages{
//     position: relative;
//     z-index: 100000000000;
// }
.tabs-primary.tabs.primary {
    width: 145px;
    position: fixed;
    right: 0;
    top: 100px;
    z-index: 100000;

    li{
        list-style-type: square;
        list-style-position: inside;
        background-color: #dcdcdc;
        width: 100%;
        margin-bottom: 1px;
        padding: 3px 5px;
        font-size: 12px;
        color: #fff;

        a{
            color: #999999;
            width: 100%;
        }
    }
}
.admin-menu-site-projetim-lokal{
    display: none;
}
html body.admin-menu {
    margin-top: 0px !important;
}

/************************************************************/
/**************** HEADER ************************************/
/************************************************************/
header{
    position: fixed;
    z-index: 20000;
    background-color: $blanc;
    width: 100%;
    top: 0;
    height: 126px;
    transition: 0.3s;

    .flex{
        margin-bottom: 0;
    }
    .flex-item{
        width: calc(100% / 3);
        position: relative;
        height: 156px;

        &:nth-child(1){
            padding-top: 30px;
            transition: 0.3s;
        }
        &:nth-child(2){
            text-align: center;
            z-index: 10;
        }
        &:nth-child(3){
            text-align: right;
            padding-top: 30px;
            transition: 0.3s;

            .selection{
                margin-right: 70px;
                margin-top: 23px;
                display: inline-block;
                text-transform: uppercase;
                font-size: 1.4rem;
                transition: 0.3s;

                &:hover{
                    color: $bleu;
                }
                i{
                    color: $blanc;
                    background-color: $fdgris;
                    border-radius: 100%;
                    padding: 6px;
                    font-size: 1.1rem;
                    margin-left: 5px;
                    transition: 0.3s;

                    &.on{
                        background-color: $bleu;
                    }
                    &.active{
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
    .logo2,
    .logo{
        transition: 0.3s;
    }
    &.sticky{
        .flex-item{
            height: 75px;
        }
    }
}
.menu-burger{
    position: fixed;
    background-color: $bleu;
    border-radius: 100%;
    height: 53px;
    width: 53px;
    right: $marge;
    top: 75px;
    transform: translateY(-80%);
    cursor: pointer;
    z-index: 300000;
    transition: 0.3s;


    .menu-burger-slice{
        display: inline-block;
        background-color: $blanc;
        height: 2px;
        width: 20px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: 0.3s;

        &:nth-child(1){
            top: 20px;
        }
        &:nth-child(2),
        &:nth-child(3){
            top: 26px;
        }
        &:nth-child(4){
            top: 32px;
        }
    }
    &.active{
        background-color: $blanc;

        .menu-burger-slice{
            background-color: $bleu;

            &:nth-child(1) {
                top: 24px;
                width: 0%;
            }
            &:nth-child(2) {
                transform: rotate(45deg);
                left: 17px;
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
                left: 17px;
            }
            &:nth-child(4) {
                top: 24px;
                width: 0%;
            }
        }
    }
    &:hover{
        border-color: rgba($grisf, 0.5);
    }
}

/************************************************************/
/**************** MENU **************************************/
/************************************************************/
.main-nav{
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba($blanc, 0);
    left: 0;
    top: 0;
    z-index: -1;
    transition: z-index 0.5s, 0.3s;

    .main-nav-content{
        background-color: $bleu;
        right: 0;
        top: 0;
        min-width: 490px;
        width: calc(28% + #{$marge});
        height: 100vh;
        padding: 50px 50px 0;
        color: $blanc;
        position: absolute;
        transform: translateX(100%);
        transition: 0.3s;
        overflow: auto;
        min-height: 500px;
    }
    .links{
        li{
            text-transform: uppercase;
            list-style-type: none;
            margin-bottom: 15px;

            a{
                color: $blanc;
                font-size: 2.4rem;
                font-weight: 700;
                display: inline-block;
                position: relative;

                &:after{
                    content: '';
                    width: 0;
                    height: 1px;
                    background-color: $blanc;
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    bottom: -2px;
                    transition: 0.3s;
                }
                &:hover,
                &.active{
                    &:after{
                        width: 100%;
                    }
                }
            }
            &:nth-child(n+6){
                margin-bottom: 10px;

                a{
                    font-weight: 400;
                    font-size: 1.8rem;
                }
            }
            &:nth-child(5),
            &:nth-child(12){
                margin-bottom: 50px;
            }
            &:nth-child(13){
                display: none;
            }
            .menu-selections{
                display: none;
            }
        }
    }
    .selection{
        display: none;
    }
    .adresse{
        position: absolute;
        bottom: 20px;
        left: 50px;
        font-size: 1.3rem;
        line-height: 2.4rem;
    }
    .mentions{
        position: absolute;
        bottom: 50px;
        left: 50px;
        font-size: 1.3rem;
    }
    &.active{
        z-index: 150000;
        background-color: rgba($blanc, 0.3);
        overflow: auto;

        .main-nav-content{
            transform: translateX(0);
        }
    }
    &.actived{

    }
}
.reseaux-menu{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 100px;
    left: 50px;

    p{
        font-family: $satisfy;
        font-size: 2.1rem;
        margin-right: 15px;
        //margin-bottom: 15px;
    }
    li{
        display: inline-block;
        margin: 0 5px;
    }
    a{
        display: inline-block;
        border: 3px solid $blanc;
        border-radius: 100%;
        width: 48px;
        text-align: center;
        padding: 8px 0;
        transition: 0.3s;

        i{
            font-size: 2.5rem;
        }
        &:hover{
            color: $bleu2;
            border-color: $bleu2;
        }
    }
}

/************************************************************/
/**************** RECHERCHE BIEN ****************************/
/************************************************************/
.recherche-bien{
    background-image: url(../images/fd-recherche2.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: auto 156px;
    margin-top: -30px;
    position: relative;
    z-index: 1;

    .wrapper-metro{
        height: 230px;
    }
    .back{
        top: 195px;
        left: $marge;
    }
    .titre1{
        text-align: center;
        padding-top: 30px;
    }
    .titre2{
        color: $blanc;
        position: relative;
        text-align: center;
        padding-top: 53px;
        padding-bottom: 10px;

        &:before,
        &:after{
            content: '';
            width: 8px;
            height: 8px;
            background-color: $blanc;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            margin: 0 7px;
        }
    }
    .gradient{
        background: linear-gradient(to bottom, $blanc 90%,rgba($fdgris, 0.4) 100%);
        padding-bottom: 30px;
    }
    .more-filtre{
        position: absolute;
        bottom: 15px;
        right: 15px;

        span{
            background-color: $gris;
            color: $blanc;
            display: inline-block;
            border-radius: 100%;
            line-height: 0;
            width: 12px;
            height: 12px;
            text-align: center;
            vertical-align: middle;
            font-size: 1.2rem;
            padding: 6px 0;
            margin-top: -4px;
        }
    }
    .show-map{
        text-transform: uppercase;

        .js-symbole{
            border: 5px solid #d5d4d0;
            padding: 9px 0;
            border-radius: 100%;
            display: inline-block;
            font-size: 2.5rem;
            line-height: 1;
            width: 30px;
            height: 30px;
            line-height: 0;
            text-align: center;
            vertical-align: middle;
            margin-right: 5px;
        }
    }
    .menu-recherche{
        margin-top: 12px;
        box-shadow: 0px 5px -5px rgba($grisf, 0.5) inset;
        display: flex;
        justify-content: center;

        li{
            color: $blanc;
            line-height: 2.5rem;
            padding: 11px 30px 9px;
            overflow: hidden;
            position: relative;
            vertical-align: top;
            transition: 0.3s;
            cursor: pointer;
            list-style-type: none;
            background-color: rgba($blanc,0.4);
            margin: 0 4px;
            font-size: 1.5rem;
            font-weight: 700;
            text-transform: uppercase;
            border-radius: 0 10px 0 0;

            &:nth-child(2){
                border-radius: 10px 0 0 0;
            }
            &:after{
                content: '\e904';
                font-family: $projetim;
                color: $blanc;
                font-size: 1rem;
                transform: rotate(90deg);
                display: inline-block;
                margin-left: 10px;
                margin-top: -6px;
                vertical-align: middle;
            }
            i{
                display: inline-block;
                vertical-align: middle;
                font-size: 2rem;
                color: $blanc;
            }
            &.active{
                background-color: rgba($blanc, 1);
                color: $grisf;

                &:after{
                    color: $grisf;
                }
                i{
                    color: $bleu;
                }
            }
            span{
                text-transform: uppercase;
                font-size: 1.4rem;
                font-weight: 700;
            }
        }
    }
}
.filter-bien{
    padding-top: 20px;
    text-align: center;

    .front &{
        display: none;
    }
    .flex{
        max-width: 1000px;
        width: 100%;
    }
    input, select{
        //border-color: $blanc;
        text-transform: uppercase;
    }
    input[type="button"]{
        background-color: $bleu;
        border-color: $bleu;
        color: $blanc;
        font-weight: 700;
        padding-left: 10px;
        padding-right: 10px;
    }
    label{
        font-family: $satisfy;
        font-size: 2.2rem;
    }
    .views-show-only-available{
        position: relative;
        margin-left: 50px;

        label{
            &:after{
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                background-color: $blanc;
                vertical-align: middle;
                margin-left: 10px;
                border: 4px solid $fdgris;
                transition: 0.3s;
                cursor: pointer;
            }
        }
        input:checked + label{
            &:after{
                background-color: $bleu;
            }
        }
    }
}
.formulaire-bien{
    position: relative;
    display: table;
    width: 100%;

    input[type="radio"],
    input[type="checkbox"]{
        height: 1px;
        width: 1px;
        opacity: 0;
        position: absolute;
        left: -9999px;
    }
    .filtre{
        display: table;
        margin: 0 auto;
        padding: 15px 0 25px;
        background-image: url(../images/ombre-filtre.png);
        background-repeat: no-repeat;
        background-position: center 52px;
        width: 100%;
        text-align: center;

        p{
            display: inline-block;
            font-family: $satisfy;
            vertical-align: middle;
            font-size: 2rem;
            margin-right: 20px;
        }
        label{
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;

            &:before{
                content: '';
                border: 5px solid $fdgris;
                background-color: $blanc;
                width: 11px;
                height: 11px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
            }
        }
        input[type="checkbox"]:checked + label:before,
        input[type="radio"]:checked + label:before{
            background-color: $bleu;
        }
    }
}

.metro,
.tram{
    margin: 0 auto;
    //padding: 90px 0;
    display: table;
    max-width: 980px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.gif-metro{
    position: absolute;
    bottom: -97px;
    right: 0;
}
.station{
    p{
        display: inline-block;
        margin-right: 20px;

        i{
            font-size: 2rem;
            display: inline-block;
            vertical-align: middle;
            color: $grisf;
        }
        span{
            color: $blanc;
            width: 35px;
            border-radius: 100%;
            text-align: center;
            font-weight: 700;
            display: inline-block;
            font-size: 2.2rem;
            text-transform: uppercase;
            padding: 4px 0;
            vertical-align: middle;
            background-color: $bleu;
        }
    }
    .logo-map{
        position: absolute;
        left: 52.5%;
        top: -22px;
        cursor: pointer;
    }
    .projetim-here{
        position: absolute;
        left: 575px;
        top: -25px;
        background-color: $bleu;
        color: $blanc;
        display: inline-block;
        padding: 5px 10px;
        z-index: -1;
        margin-left: 25px;
        box-shadow: 0 5px 5px rgba($gris, 0.5);
        display: none;

        &:before{
            content: url(../images/fleche-here.png);
            display: inline-block;
            position: absolute;
            left: -24px;
            top: 13px;
        }
        &.active{
            z-index: 10;
            display: block;
        }
    }
    label{
        padding-left: 20px;
        position: relative;
        margin-right: 15px;
        cursor: pointer;
        position: relative;

        .name-station{
            transform: rotate(-40deg);
            font-size: 1.3rem;
            display: inline-block;
            position: absolute;
            left: 2px;
            top: -16px;
            white-space: nowrap;
            transform-origin: left top;
            width: 150px;
            color: lighten($gris, 20%);
        }
        .rond{
            background-color: $blanc;
            display: inline-block;
            position: absolute;
            left: 2px;
            top: -1px;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            border: 3px solid $bleu;
            z-index: 20;

            &:before{
                content: '';
                position: absolute;
                background-color: $blanc;
                left: 50%;
                top: 50%;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                transform: translate(-50%, -50%);
            }
        }

        &:after{
            content: '';
            position: absolute;
            display: inline-block;
            left: 15px;
            top: 7px;
            width: 35px;
            height: 5px;
            background-color: $bleu;
            z-index: 5;
        }
        &:last-child:after{
            display: none;
        }
    }
    input[type="checkbox"]:checked + label .name-station{
        color: $gris;
    }
    input[type="checkbox"]:checked + label .rond:before{
        background-color: $bleu;
    }
    input[type="checkbox"]:disabled + label .rond{
        background-color: transparent;
        border-color: transparent;
    }
    input[type="checkbox"]:disabled + label .rond:before{
        background-color: $apricot;
    }

    &.ligne-a{
        position: relative;
        margin-top: 100px;
        height: 33px;

        label{
            &:before{
                border-color: $bleu;
            }
            &:after{
                background-color: $apricot;
            }
            &[for="jean-jaures"]{
                top: 30px;
                margin-right: 56px;

                .rond{
                    top: -10px;
                    left: 2px;
                }
                &:after{
                    transform: rotate(16deg);
                    left: -71px;
                    top: -1px;
                    width: 162px;
                }
                .name-station{
                    display: none;
                }
            }
            &[for="capitole"]{
                margin-right: 56px;

                &:after{
                    display: none;
                }
            }
            &:nth-of-type(n+14){
                top: 44px;

                .name-station{
                    left: -117px;
                    top: 117px;
                    text-align: right;
                }
            }
        }
        input[type="checkbox"]:disabled + label:before{
            background-color: $apricot;
        }
        input#jean-jaures[type="checkbox"]:disabled + label:before{
            top: 6px;
            left: 0px;
            font-size: 5.3rem;
            line-height: 14px;
        }
        input#jean-jaures[type="checkbox"]:checked + label:before{
            font-size: 5.2rem;
            line-height: 16px;
        }
        p{
            span{
                background-color: $apricot;
            }
        }
    }
    &.ligne-b{
        margin-top: 10px;
        position: relative;
        z-index: 5;
        margin-bottom: 68px;

        p{
            margin-right: 55px;

            span{
                background-color: $jaune2;
            }
        }
        input[type="checkbox"]:checked + label:before{
            //background-color: $jaune2;
        }
        label{
            .rond:before{
                border-color: $bleu;
            }
            &:after{
                background-color: $jaune2;
            }
            .name-station{
                left: -117px;
                top: 117px;
                text-align: right;
            }
            &[for="jean-jaures"]{
                top: -32px;
                margin-right: 56px;

                .rond{
                    display: none;
                }
                &:after{
                    transform: rotate(-16deg);
                    left: -70px;
                    top: 17px;
                    width: 166px;
                }
                span{
                    padding-right: 10px;
                }
            }
            &[for="francois-verdier"]{
                margin-right: 56px;

                &:after{
                    display: none;
                }
            }
            &:nth-of-type(n+13){
                top: -44px;

                .name-station{
                    left: 2px;
                    top: -16px;
                    text-align: left;
                }
            }
        }
        input[type="checkbox"]:disabled + label .rond:before{
            background-color: $jaune2;
        }
    }

    &.ligne-c{
        margin-top: 185px;
        display: none;

        input[type="checkbox"]:disabled + label .rond:before{
            background-color: $violet2;
        }
        label{
            &:after{
                background-color: $violet2;
            }
        }
        p{
            .rond:before{
                background-color: $violet2;
            }
            span{
                background-color: $violet2;
            }
        }
    }


    &.ligne-t1{
        margin-top: 115px;
        height: 33px;
    }
    &.ligne-t2{
        margin-bottom: 35px;
    }
    &.ligne-t1,
    &.ligne-t2{
        display: none;

        input[type="checkbox"]:disabled + label .rond:before{
            background-color: $bleu3;
        }
        label{
            & .rond{
                border-color: $bleu;
            }
            &:after{
                background-color: $bleu3;
            }
        }
        p{
            .rond{
                background-color: $bleu3;
            }
            span{
                background-color: $bleu3;
            }
        }
    }
    &.ligne-t2{
        margin-top: 8px;

        p{
            margin-right: 220px;
        }
        label{
            .name-station{
                left: -117px;
                top: 117px;
                text-align: right;
            }
            &:last-child:after{
                display: inline-block;
                transform: rotate(-19deg);
                left: 6px;
                top: -13px;
                width: 125px;
            }
        }
    }
}
.bloc-recherche{
    //height: 180px;

    h2{
        font-size: 1.8rem;
        text-align: center;
        color: $bleu;
        margin-bottom: 20px;
        margin-top: 15px;
    }
}
.souhait-recherche{
    padding: 0 30px;
    margin-top: 30px;

    .flex{
        margin-bottom: 0;
    }
    label{
        border: 1px solid $gris4;
        width: calc((100% - 40px) / 3);
        padding: 10px 20px;
        text-align: center;
        text-transform: uppercase;
        line-height: 4rem;
        cursor: pointer;
        transition: 0.3s;
        color: $bleu;
        font-weight: 700;
        font-size: 2.4rem;

        i{
            font-size: 4rem;
            display: inline-block;
            vertical-align: middle;
            margin-right: 20px;
            color: $bleu;
            margin-top: -4px;
        }
        span{
            display: inline-block;
            vertical-align: middle;
            font-weight: 700;
        }
    }
    input[type="radio"]:checked + label{
        background-color: $bleu;
        color: $blanc;

        i{
            color: $blanc;
        }
    }
    input[type="radio"]:disabled + label{
        opacity: 0.4;
        border-color: $gris4;
        color: $gris;

        i{
            color: $gris;
        }
    }
}

.type-recherche{
    label{
        border: 1px solid $gris4;
        width: calc((100% - 25px) / 6);
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.4rem;
        cursor: pointer;
        transition: 0.3s;
        color: $bleu;
        font-weight: 700;

        i{
            display: block;
            font-size: 5rem;
            margin-bottom: 10px;
        }
    }
    input[type="checkbox"]:checked + label{
        background-color: $bleu;
        color: $blanc;
    }
    .recap{
        display: table;
        margin: 20px auto;
        text-align: center;

        p, div{
            display: inline-block;
        }
        ul{
            display: inline-block;
        }
        span.texte{
            font-family: $satisfy;
            font-size: 2.2rem;
            text-align: center;
            color: $gris;
            text-transform: none;
            font-weight: 400;
        }
        .bg-bleu,
        li{
            display: inline-block;
            list-style-type: none;
            padding-right: 10px;
            margin: 0 5px;
        }
        i{
            color: $blanc;
            background-color: $bleu;
            font-size: 1.3rem;
            margin-left: 5px;
            cursor: pointer;
        }
        span{
            display: inline-block;
            font-family: $asap;
            color: $blanc;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.4rem;
            padding: 7px 10px;

            i{
                display: inline-block;
            }
            &.js-metro-span{
                color: $gris;
            }
            &.js-station,
            &.js-type-offre-text{
                position: relative;
                cursor: pointer;

                &:after{
                    content: '\e929';
                    color: $blanc;
                    display: inline-block;
                    font-family: $projetim;
                    font-style: 1.5rem;
                    margin-left: 10px;
                    font-weight: 400;
                }
            }
            &.separateur{
                color: $gris;

                &:after{
                    display: none;
                }
            }
        }
    }
}

.affiner-recherche{
    //display: none;
    margin: 20px 50px 0;
    text-transform: uppercase;

    .flex{
        align-items: center;
        max-width: 1000px;
    }
    p{
        font-family: $satisfy;
        font-size: 2.2rem;
        text-transform: none;
        text-transform: none;
    }
    input,
    select{
        width: 15%;
        text-transform: uppercase;

        &[type="submit"]{
            //font-size: 1.4rem;
            width: auto;
            background-color: $bleu;
            color: $blanc;
            border-color: $bleu;
            padding: 3px 10px;
        }
    }
    select{
        width: 25%;
    }
}

.form-no-result-bloc1{
    padding: 50px 10px 20px 10px;

    .webform-component--texte{
        width: 100%;
        margin-bottom: 50px;

        h2{
            color: $grisf;
            background: url('../images/separation-contact-gris.png') no-repeat center bottom transparent;
            padding-bottom: 23px;
            margin-bottom: 18px;
        }
        p{
            color: $grisf;
            font-size: 1.333em;
            line-height: 18px;

            strong{
                font-size: 1em;
            }
        }
    }

    .messages--error{
        text-align: center;
        margin-bottom: 30px;
    }

    form{
        max-width: 930px;
        width: 100%;
        display: table;
        margin: 0 auto;

        .webform-component--texte{
            width: 100%;
            margin-bottom: 50px;

            h2{
                color: $grisf;
                background: url('../images/separation-contact-gris.png') no-repeat center bottom transparent;
                padding-bottom: 23px;
                margin-bottom: 18px;
            }
            p{
                color: $grisf;
                font-size: 1.333em;
                line-height: 18px;

                strong{
                    font-size: 1em;
                }
            }
        }
        fieldset{
            width: 29.83%;
            float: left;
            border: 0;

            margin-bottom: 13px;

            &.webform-component--groupe2{
                margin: 0 0.5%;
                width: 38.83%;
            }
            textarea{
                //height: 125px;
                height: 158px;
            }
            input, select{
                width: 100%;
                margin-bottom: 2px;
            }
            &.webform-component--groupe3 input,
            &.webform-component--groupe3 select{
                background-color: $grisf;
                border-color: $grisf;
                color:$blanc;

                &.error{
                    border-color: red;
                }
            }
        }
        .form-actions{
            float: left;
            width: 100%;
            text-align: center;

            input[type="submit"]{
                background-color: $bleu;
                border-color: $bleu;
                color: $blanc;
                text-transform: uppercase;
                width: auto;
                cursor: pointer;
                margin-bottom: 20px;

                &:hover{
                    background-color: $grisf;
                    border-bottom: 1px solid $blanc;
                    border-right: 1px solid $blanc;
                }
            }
        }
        .captcha {
            clear: both;
        }
    }
}
.form-no-result-bloc2{
    background-color: $bleu;
    padding: 47px 0 53px 0;
    text-align: center;

    h2{
        color: $blanc;
        font-size: 1.333em;
        font-weight: 700;
        line-height: 28px;
    }
    p{
        color: $blanc;
        font-size: 1.333em;
        line-height: 30px;

        strong{
            font-weight: 700;
            font-size: 1.4em;
        }
    }
}

//.view-display-id-page_1{
.view-display-id-block_3 {
    margin-top: 50px;
    //margin-bottom: 50px;

    .flex{
        //justify-content: left;
    }
}


/*************************************************************************/
/********************************** VENDRE BIEN **************************/
/*************************************************************************/
.vendre-bien{
    padding-top: 35px;
    padding-bottom: 35px;
    text-align: center;
    color: $blanc;

    .page-nos-biens &:after,
    .page-galerie &:after{
        display: none;
    }

    h2{
        i{
            font-size: 6rem;
            position: relative;
            margin: 0 10px;
            display: inline-block;

            &:after{
                content: '?';
                position: absolute;
                left: 22px;
                top: 19px;
                font-family: $asap;
                font-size: 3.7rem;
                color: lighten($jaune, 10%);
                font-weight: 700;
            }
        }
    }
    p:not(.required-field){
        font-size: 1.8rem;
        margin-bottom: 15px;
        line-height: 3rem;

        span{
            font-size: 4rem;
            color: $jaune;
            background-color: $blanc;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            width: 83px;
            border-radius: 100%;
            font-weight: 700;
            line-height: 8rem;
            margin: 0 15px;
        }
    }
    .message-container{
        p{
            margin-bottom: 0;
            font-size: 1.6rem;
            line-height: 1.9rem;
        }
    }
    input,
    select{
        //text-transform: uppercase;
        border-color: $blanc;
        margin-bottom: 5px;
        vertical-align: middle;

        &[type="submit"]{
            background-color: transparent;
            border-color: $blanc;
            transition: 0.3s;

            &:hover{
                color: $jaune;
                background-color: $blanc;
            }
        }
    }
    .resultat-potentiel{
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
    }
    .separateur{
        margin: 10px 0 25px;
    }
    .form-critere-vendre{
        margin-bottom: 30px;
    }
    .form-contact-vendre{
        margin: 0 auto;
        display: table;
        width: 50%;

        p{
            margin-bottom: 2px;
            text-align: left;
        }
        input:not([type="submit"]){
            width: 48%;
        }
    }
}

/*************************************************************************/
/********************************** HOMEPAGE *****************************/
/*************************************************************************/
.front .fd_titre{
    @extend .visuallyhidden;
}


/*************************************************************************/
/********************************** NOS COUP DE COEUR HOME ***************/
/*************************************************************************/
.bloc-coup-coeur{
    background-color: $fdgris;
    padding: 0 0 50px;

    .flex{
        margin-bottom: 0;
    }
}
.coup-de-coeur{
    h2{
        @extend .titre2;
        text-transform: uppercase;
        text-align: center;
        padding: 50px 0 29px 0;
    }
    .front & .blog-slider{
        padding-top: 0;
        padding-bottom: 0;
    }
}
.coup-de-coeur-home{
    background-color: $fdgris;
}

/*************************************************************************/
/********************************** LISTING BIEN *************************/
/*************************************************************************/
.views-exposed-form{
    // display: none;
}
.node-type-liste-biens .items {
    h2 {
        padding-top: 50px;
        text-align: center;

        span {
            text-transform: none;
            font-size: 2.2rem;
        }
    }
}
.view-biens{
    .flex{
        &:after {
          content: '';
          flex: auto;
        }
    }
    .node-type-liste-biens &.wrap{
        padding: 0 30px;
        width: 100%;
        max-width: 100%;
    }
    .node-type-liste-biens & .flex{
        justify-content: left;
    }
    .flex-item{
        margin-right: 20px;
        position: relative;
        //flex-basis: calc((100% - 40px) / 3);
        //max-width: calc((100% - 40px) / 3);
        flex: 0 32%;
        margin-bottom: 30px;
        position: relative;

        img{
            width: 100%;
            height: auto;
        }
        &:nth-child(3n){
            margin-right: 0;
        }
        .overlay{
            position: absolute;
            background-color: rgba($noir, 0.2);
            width: 100%;
            height: 100%;
            z-index: 10;
            left: 0;
            top: 0;
            opacity: 0;
            transition: 0.3s;

            p{
                position: absolute;
                top: 50%;
                left: 0;
                padding: 15px;
                width: 100%;
                transform: translateY(-50%);
                font-size: 1.8rem;
                line-height: 2.4rem;
                color: $blanc;
            }
            i{
                font-size: 3.2rem;
                display: block;
                color: $blanc;
                line-height: 6rem;
            }
            img{
                width: auto;
                display: block;
                margin: 10px auto;
            }
            .barre-horizontale{
                width: 50%;
                background-color: $blanc;
                position: relative;

                &:before,
                &:after{
                    background-color: $blanc;
                }
            }
        }
    }
    .bien-neuf{
        text-transform: uppercase;
        color: $bleu;
        font-weight: bold;
    }
    .ideal-investisseur{
        text-transform: uppercase;
        color: $violet;
        font-weight: bold;
    }
    img.pasdispo{
        height: auto;
        left: -7px;
        position: absolute;
        top: 18px;
        width: auto;
    }
    img.exclu{
        height: auto;
        right: -21px;
        position: absolute;
        top: 18px;
        width: auto;
    }
    .titre-image{
        position: relative;
        margin-bottom: 15px;
        transition: 0.5s;
        overflow: hidden;

        img{
            transition: 0.3s;
        }
        h3{
            background: linear-gradient(rgba(87,98,104,0) 0%,rgba(87,98,104,0.8) 100%);
            text-align: center;
            padding: 23px 0px 8px 0px;
            position: absolute;
            bottom: 0;
            width:100%;
            transition: 0.3s;

            a{
                color: $blanc;
                font-size: 1.8rem;
                font-weight: 700;
                padding: 0 10px;

                &:hover{
                    text-decoration: none;
                }
            }
        }
        &:hover{
            opacity: 0.7;
            text-decoration: none;

            img{
                transform: scale(1.1);
            }
            .overlay{
                opacity: 1;
            }
            .section-nos-coup-de-coeur &{
                opacity: 1;

                h3{
                    opacity: 0;
                }
                img[typeof="foaf:Image"]{
                    filter: blur(2px);
                }
            }
        }
    }
    strong{
        font-size: 1.8rem;
        font-weight: 700;
    }
    p{
        text-align: center;
        margin-bottom: 0;
    }
    .bulle {
        background: url('../images/fd_bulle.png') no-repeat right top transparent;
        color: $blanc;
        padding: 5px 25px 10px 15px;
        position: absolute;
        right: 20px;
        text-transform: uppercase;
        //top: 79%;
        font-size:1.3rem;
        opacity: 0;
        z-index: -1;
        transition: 0.3s;
    }
    .icon-selection{
        position: absolute;
        right: 0;
        //top: 76%;
        font-size: 2.1rem;
        color: $gris4;
        transition: 0.3s;

        &:hover{
            color: $bleu;
        }
        &.active{
            color: $bleu;
        }
    }
    .roll-selection:hover{
        .bulle{
            opacity: 1;
            z-index: 10;
        }
        i{
            color: $bleu;
        }
    }
    .bien-station{
        font-size: 1.3rem;
        min-height: 24px;
    }
}

/*************************************************************************/
/********************************** REGION PUSH **************************/
/*************************************************************************/
.block-push{
    padding-top: 23px;
    padding-bottom: 28px;
    display: table;
    margin: 0 auto;
    width: 100%;

    .page-node-27 &{
        background: url('../images/pattern-gris2.png') repeat-x center top $fdgris;
        padding: 28px 0 23px 0;
    }
    .content{
        @extend .wrap;
    }
    ul{
        display: table;
        width: 100%;
    }
    li{
        display: table-cell;
        vertical-align: top;
        width: 33.33%;
        text-align: center;
        height: 189px;
        background: url('../images/separation-push.png') no-repeat left top transparent;
        padding: 10px 12px 0;

        &:first-child{
            background-image:none;
        }
        a{
            width: 100%;
            display: inline-block;
            padding: 57px 0 0 0;
            font-size: 1.6rem;
            color: $gris;
            position: relative;

            &:before{
                content: '';
                font-family: $projetim;
                font-size: 4rem;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            .titre{
                font-size: 3.2rem;
                font-family: $satisfy;
                display: inline-block;
            }
        }
        &.investir a:before{
            content: '\e93b';
        }
        &.seclection a:before{
            content: '\e906';
        }
        &.galerie a:before{
            content: '\e928';
        }
        &.proprietaire a:before{
            content: '\e904';
        }
        &.projet a:before{
            content: '\e93a';
        }
        form{
            padding-top: 18px;

            input.loyer{
                background-color: $fdgris;
                border: 1px solid #BAB9B5;
                text-transform: uppercase;
                color: $gris;
                width: 200px;
            }
            .btn2{
                margin-left: 3px;
                font-size: 1.3rem;
                padding: 5px 7px;
                border: 1px solid #BFA000;
                display: inline-block;
                text-transform: uppercase;
                background-color: #BFA000;
                color: $blanc;
                cursor: pointer;

                &:hover{
                    background-color: $grisf;
                    border-color: $grisf;
                }
            }
        }
    }
}

.front .bottom li.seclection,
.front .bottom li.proprietaire{
    display: none;
}
.page-node-27 .push li.projet{
    display: none;
}


/*************************************************************************/
/********************************** BLOC SLIDER **************************/
/*************************************************************************/
.bloc-slider{
    .block-title{
        display: none;
    }
    .logo-projetim{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
    }
    .swiper-container {
        width: 100%;
        height: 325px;
        margin: 30px 0;
        position: relative;

        .swiper-slide {
            width: 100%;
            height: 325px;
            //display: flex;
            background-color: $bleu;

            a{
                width: 100%;
                display: flex;
                height: 325px;
            }
            .fd-slider{
                flex: 0 50%;
                height: 325px;
                //background-size: contain;
                background-repeat: no-repeat;
                background-position: right center;
            }
            .content{
                flex: 0 50%;
                background-color: $bleu;
                vertical-align: middle;
                color: $blanc;
                padding: 0 calc(#{$marge} + 100px) 0  100px;
                text-align: center;
                align-self: center;

                .titre5{
                    color: $grisf;
                    font-size: 1.5rem;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                }
                .titre4{
                    font-weight: 400;
                    text-transform: none;
                }
                .titre3{
                    font-size: 2.2rem;
                    line-height: 2.6rem;
                    padding-top: 30px;
                    margin-top: 30px;
                    background-image: url(../images/separation-slider.png);
                    background-repeat: no-repeat;
                    background-position: center top;
                }
                .btn-blanc:hover{
                    color: $bleu;
                }
            }
        }
    }
}

/*************************************************************************/
/********************************** DETAILS BIENS ************************/
/*************************************************************************/
.detail-top{
    padding-top: 55px;
    background: linear-gradient(to bottom, $fdgris 0%,$fdgris 41%,$blanc 41%,$blanc 100%);
    margin-top: -30px;

    .wrap{
        text-align: center;
        position: relative;
    }
    h1{
        font-weight: 700;
        font-size: 3rem;
        text-align: center;
        color: $grisf;
        padding-bottom: 10px;
    }
    .descriptif{
        padding: 0 100px;
    }
    .descriptif p{
        display: none;
    }
    .descriptif p:first-child{
        display: block;
    }
    p{
        font-size: 2rem;
        line-height: 28px;
        font-weight: 300;
        width: calc(100% - 300px);
        margin: 0 auto;
    }
    .prix{
        font-size: 2.6rem;
        text-align: center;
        padding-bottom: 25px;
        line-height: normal;
    }
    .bien-neuf {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 2rem;
        color: $bleu;
        margin-top: -20px;
        margin-bottom: 20px;
    }
    .content-flex{
        padding: 0 50px;
        margin-top: 30px;
    }
    .galerie{
        width: calc(100% - 300px);
        display: inline-block;
        margin: 0 40px;
        vertical-align: top;
        position: relative;
    }
    .swiper-container {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
    }
    .swiper-slide {
        background-size: cover;
        background-position: center;
        height: 100%;
        background-image: radial-gradient(45px 45px,$blanc 100px,#cfcbc6 300px);
    }
    .gallery-top {
        width: 100%;
        height: 100%;

        .slide-horizontale{
            img{
                width: 100%;
                height: auto;
            }
        }
        .slide-verticale{
            img{
                width: auto;
                height: 100%;
            }
        }
        .swiper-button-next{
            background-image: url(../images/fleche-slide2.png)
        }
        .swiper-button-prev{
            background-image: url(../images/fleche-slide.png)
        }
    }
    .gallery-thumbs {
        height: auto;
        box-sizing: border-box;
        width: 50px;
        display: inline-block;
        vertical-align: top;
        height: 280px;
        margin-top: 125px;

        li{
            list-style-type: none;
        }
    }
    .gallery-thumbs .swiper-slide {
        width: 50px;
        height: 50px;
        opacity: 0.4;
    }
    .gallery-thumbs .swiper-slide-active {
        opacity: 1;
    }
}
.back{
    position: absolute;
    left: 0;
    top: -10px;
    background: url('../images/fleche-retour.png') no-repeat left top transparent;
    padding: 10px 0 10px 42px;
    text-transform: uppercase;
    color: $grisf;
    font-size: 1.3rem;
}
.link-action{
    display: inline-block;
    vertical-align: top;
    margin-top: 125px;

    li{
        list-style-type: none;
        margin-bottom: 10px;
        background-color: $fdgris;
        padding: 10px;
        border-radius: 100%;
        line-height: 0;
        transition: 0.2s;
        position: relative;

        a{
            font-size: 2rem;
            width: 40px;
            height: 40px;
            color: $fdgris;
            border-radius:70px;
            cursor: pointer;
            display: inline-block;
            transition: 0.4s;
            background-color: $blanc;
            position: relative;
            z-index: 2;

            i{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: 0.3s;
            }
        }
        &:after{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: $bleu;
            left: 50%;
            top: 50%;
            border-radius: 100%;
            transform: translate(-50%, -50%) scale(0.2);
            transition: 0.4s;
        }
        &.selection{
            &.active{

                background-color: $bleu;

                a{
                    color: $bleu;
                }
            }
        }
        &:hover{
            &:after{
                transform: translate(-50%, -50%) scale(1);
            }
            a{
                i{
                    color: $bleu;
                }
            }
        }
    }
}
.bien-info{
    background-color: $fdgris;
    margin-top: -120px;
    padding-top: 200px;
    padding-bottom: 100px;
    width: 100%;
    display: table;

    .flex-invest{
        ul{
            flex: 0 22.5%;
        }
    }
    ul{
        flex: 0 31%;

        li{
            list-style-type: none;
            width: 100%;
            border-bottom: 1px solid $blanc;
            padding: 6px 0;
            display: table;

            &:first-child{
                border-top: 1px solid $blanc;
            }
            strong{
                float: right;
                max-width: 50%;
                text-align: right;
            }
            .date-display-single{
                font-weight: 700;
            }
            &.jauge{
                text-align: center;
                font-weight: 700;

                img{
                    display: block;
                    margin-bottom: 5px;
                    margin: 0 auto;
                }
            }
            &.col1{
                display: block;

                strong{
                    display: block;
                    float: none;
                    max-width: 100%;
                    text-align: left;
                    margin-top: 5px;
                }
            }
        }
        &:before{
            content: '';
            font-family: $projetim;
            font-size: 4.2rem;
            display: block;
            text-align: center;
            color: darken($fdgris, 15%);
            margin-bottom: 20px;
        }
        &:nth-child(1){
            &:before{
                content: '\e92f';
            }
        }
        &:nth-child(3){
            &:before{
                content: '\e91d';
            }
            li:last-child{
                border-bottom: 0px;
            }
        }
        &:nth-child(2){
            &:before{
                content: '\e91e';
            }
        }
        &:nth-child(4){
            &:before{
                content: '\e91b';
            }
            li:last-child{
                border-bottom: 0px;
            }
        }
    }
    .bloc-recherche{
        float: left;
        width: 33%;
        padding: 0 0 0 6%;
        background: url('../images/separation-info-bien.png') no-repeat left top transparent;
        height: 285px;
        text-align: center;

        a{
            width: 100%;
            height: 100%;
            display: inline-block;

            padding: 57px 0 0 0;
            font-size: 1.067em;
            color: $gris;
        }
        .titre{
            font-size: 2em;
            font-family: $satisfy;
            padding-bottom: 15px;
            display: inline-block;
        }
        .btn{
            width: 194px;
            font-size: 1em;
        }
    }
}
.visite{
    padding: 85px 0 35px 0;
    position: relative;
    text-align: center;

    .picto-calendar{
        position: absolute;
        top:-34px;
        left: 50%;
        margin: 0 -56.5px;
    }
    a{
        width: 100%;
        height: 100%;
        display: inline-block;
        font-size: 1.067em;
        color: $gris;
    }
    .titre{
        font-size: 2em;
        font-family: $satisfy;
        padding-bottom: 15px;
        display: inline-block;
    }
    .btn{
        border: 1px solid $bleu;
        background-color: $bleu;
        color: $blanc;
    }
}
.vendu {
    left: -20px;
    position: absolute;
    top: 60px;
    z-index: 1000;
}
.exclu {
    right: -21px;
    position: absolute;
    top: 60px;
    z-index: 1000;
}
.biens-like{

    h2{
        text-transform: uppercase;
        text-align: center;
        font-size: 1.467em;
        padding: 35px 0 30px;
        font-weight: 700;
    }
}
.rv{
    padding: 60px 0 50px 0;
    background-color: $violet;
    text-align: center;
    position: relative;

    .picto{
        position: absolute;
        top:-63px;
        left: 50%;
        margin: 0 -56px;
    }
    h2{
        color: $blanc;
        padding-bottom: 10px;
        font-family: $satisfy;
        font-size: 2.333em;
        text-align: center;
    }
    p{
        color: $blanc;
    }
    .btn{
        background-color: $bleu;
        color: $blanc;
        border-color: $bleu;
    }
}

.dpe{
    display: inline-block;
    background-color: #00A8C2;
    color: $blanc;
    text-transform: uppercase;
    position: relative;
    border-left: 10px solid transparent;
    padding: 2px 10px 2px 15px;
    width: 75px;
    border-color: $gris;

    &:before{
        content: '';
        display: inline-block;
        border-color: transparent transparent transparent $gris;
        border-style: solid none solid solid;
        border-width: 11px 0 11px 7px;
        position: absolute;
        top: 0;
        left: 0;
    }

    &.dpe-a{
        border-color: #339A33;

        &:before{
            border-color: transparent transparent transparent #339A33;
        }
    }
    &.dpe-b{
        border-color: #33CC33;

        &:before{
            border-color: transparent transparent transparent #33CC33;
        }
    }
    &.dpe-c{
        border-color: #CCFF33;

        &:before{
            border-color: transparent transparent transparent #CCFF33;
        }
    }
    &.dpe-d{
        border-color: #FFFF00;

        &:before{
            border-color: transparent transparent transparent #FFFF00;
        }
    }
    &.dpe-e{
        border-color: #FFCC00;

        &:before{
            border-color: transparent transparent transparent #FFCC00;
        }
    }
    &.dpe-f{
        border-color: #FF9A33;

        &:before{
            border-color: transparent transparent transparent #FF9A33;
        }
    }
    &.dpe-g{
        border-color: #FF0000;

        &:before{
            border-color: transparent transparent transparent #FF0000;
        }
    }
}

.ges{
    display: inline-block;
    background-color: #00A8C2;
    color: $blanc;
    text-transform: uppercase;
    position: relative;
    border-left: 10px solid transparent;
    padding: 2px 10px 2px 15px;
    width: 75px;
    border-color: $gris;

    &:before{
        content: '';
        display: inline-block;
        border-color: transparent transparent transparent $gris;
        border-style: solid none solid solid;
        border-width: 11px 0 11px 7px;
        position: absolute;
        top: 0;
        left: 0;
    }

    &.ges-a{
        border-color: #E1C2F8;

        &:before{
            border-color: transparent transparent transparent #E1C2F8;
        }
    }
    &.ges-b{
        border-color: #E1C2F8;

        &:before{
            border-color: transparent transparent transparent #E1C2F8;
        }
    }
    &.ges-c{
        border-color: #CB95F3;

        &:before{
            border-color: transparent transparent transparent #CB95F3;
        }
    }
    &.ges-d{
        border-color: #CB95F3;

        &:before{
            border-color: transparent transparent transparent #CB95F3;
        }
    }
    &.ges-e{
        border-color: #A74DEB;

        &:before{
            border-color: transparent transparent transparent #A74DEB;
        }
    }
    &.ges-f{
        border-color: #A74DEB;

        &:before{
            border-color: transparent transparent transparent #A74DEB;
        }
    }
    &.ges-g{
        border-color: #8A19DF;

        &:before{
            border-color: transparent transparent transparent #8A19DF;
        }
    }
}
.fancybox-skin{
    border-radius:0;
    background-color: $blanc;
}
.fancybox-close {
    background-image: none;
    color: $blanc;
    font-family: $asap;
    font-size: 0.833em;
    right: 5px;
}
a.fancybox-dpe {
    &:hover {
        text-decoration: underline;
    }
}
.action-choices,
.action-choices-bien-vendu{
    text-align: center;
    padding-bottom: 30px;
    transition: 0.3s;
    height: 250px;

    .node-type-espace-investisseur &{
        height: auto;
    }

    .col{
        width: 50%;
        transition: 0.3s;
        display: inline-block;
        position: absolute;
        z-index: 1000;
        background-color: $blanc;
        z-index: 10;
        margin-top: 5px;

        &.active{
            width: 100%;
            z-index: 100;
        }
        &:first-child{
            padding-left: $marge;
        }
        &:last-child{
            padding-right: $marge;
        }
    }
    i.icon-visite,
    i.icon-question2{
        font-size: 4.2rem;
        background-color: $bleu;
        display: inline-block;
        text-align: center;
        border: 20px solid $blanc;
        border-radius: 100%;
        width: 76px;
        color: $blanc;
        padding: 17px 0;
        margin-top: -57px;
        margin-bottom: 15px;
    }
    .titre3{
        margin-bottom: 10px;
    }
    p{
        margin-bottom: 0;
    }
    .separation{
        margin: 20px 0;
    }
    button.annuler{
        margin-right: 5px;
    }
    .action-contact{
        left: 0;

        &.active{
            height: 400px;
            padding-left: 0;
        }
    }
    .action-visite{
        right: 0;

        &.active{
            height: 600px;
            padding-right: 0;
        }
    }
    form.webform-client-form-28{
        background-image: url(../images/icones/mail2.svg);
        background-position: right bottom;
        background-repeat: no-repeat;
    }
    form[name="visit"],
    form.webform-client-form-28{
        padding: 0 145px 30px;
        max-width: 865px;
        width: 100%;
        margin: 0 auto 20px;

        .titre3{
            font-size: 2.4rem;
            margin-bottom: 20px;
        }
    }
    .left{
        width: 49%;
    }
    .right{
        width: 49%;
    }
    input:not([type="submit"]){
        margin-bottom: 2px;
        width: 100%;
    }
    textarea{
        width: 100%;
        height: 129px;
    }
    .small{
        text-align: left;
        font-size: 1.3rem;
    }
    .js-date{
        background-color: $bleu;
        color: $blanc;
        padding: 7px 10px;
        margin: 15px 0;
        font-weight: 700;
        font-size: 2.2rem;
    }
    form .captcha,
    form .g-recaptcha {
        margin: 20px 0;
    }
}
.action-choices-bien-vendu{
    height: auto;

    .col{
        width: 100%;
    }
    .col:last-child{
        position: relative;
        left: unset;
        margin: 6px auto;
    }
}

.tableau-visite{
    position: relative;
    margin-top: 15px;

    .flex{
        width: 100%;
        list-style-type: none;
        overflow: hidden;
        //height: 295px;
        margin-bottom: 50px;
        padding: 0 50px;

        .flex-item{
            list-style-type: none;
        }
    }
    .flex-c{
        flex-direction: column;
        align-items: center;
        list-style-type: none;

        .flex-item-c{
            align-self: center;
        }
    }
    ul{
        margin: 0 10px 15px;
    }
    li:not(.date){
        list-style-type: none;
        position: relative;
        height: 40px;
        padding: 10px 0;
        text-align: center;
        border-bottom: 5px solid $blanc;

        .node-type-espace-investisseur &{
            border-bottom-color: $violet;
        }

        &:before{
            content: '';
            position: absolute;
            left: 20%;
            top: 48%;
            width: 60%;
            height: 4px;
            background-color: $fdgris;
            z-index: -1;
        }
        &.dispo{
            background-color: $bleu;
            color: $blanc;
            font-weight: 700;
            text-transform: uppercase;
            cursor: pointer;
            transition: 0.2;

            &:hover{
                background-color: darken($bleu, 10%);
            }
        }
    }
    .date{
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.9rem;
        text-align: center;
        height: 60px;

        .date-jour{
            text-transform: uppercase;
            display: block;
        }
        .date-mob{
            display: none;
        }
    }
    .barre-top{
        top: 50px;
    }
    .barre-bottom{
        bottom: 63px;
    }
    .btnPrev{
        position: absolute;
        left: 10px;
        top: -10px;
        font-size: 4.7rem;
        cursor: pointer;

        .node-type-espace-investisseur &{
            color: $bleu;
        }
    }
    .btnNext{
        position: absolute;
        right: 10px;
        top: -10px;
        display: inline-block;
        transform: rotate(180deg);
        font-size: 4.7rem;
        cursor: pointer;

        .node-type-espace-investisseur &{
            color: $bleu;
        }
    }
}

.avis-projetim{
    color: $blanc;

    .flex{
        justify-content: center;
        align-items: center;
        min-height: 215px;
        padding-bottom: 30px;
        padding-top: 30px;
    }
    .col{
        width: 50%;
    }
    .col:first-child{
        text-align: right;
        background-image: url(../images/separation-slider.png);
        background-position: 95% center;
        background-repeat: no-repeat;

        h2{
            display: inline-block;
            vertical-align: middle;
            color: $grisf;
            text-transform: uppercase;
            font-size: 1.5rem;

            span{
                text-transform: none;
                color: $blanc;
            }
        }
        img{
            display: inline-block;
            vertical-align: middle;
            margin: 0 70px 0 30px;
            background-color: $bleu;
            height: 127px;
            width: 127px;
        }
    }
    .col:last-child{
        font-size: 2rem;
        padding: 0 12% 0 20px;
        line-height: 2.8rem;

        p{
            margin-bottom: 0;
            display: inline-block;

            &:before{
                content: '«';
                margin-right: 5px;
            }
            &:after{
                content: '»';
                margin-left: 5px;
            }
        }
    }
    img{
        border-radius: 100%;
    }
}

.map-bien{
    position: relative;

    .map{
        width: 100%;
        height: 400px;
    }
    .map-info-bien{
        position: absolute;
        background-color: $blanc;
        width: 280px;
        top: 50%;
        left: 87px;
        transform: translateY(-50%);
        padding: 20px;
        text-align: center;
        z-index: 10;

        h2{
            color: $bleu;
            margin-bottom: 10px;
        }
        ul{
            margin: 0;
        }
        li{
            list-style-type: none;
            background-color: $gris;
            text-transform: uppercase;
            color: $blanc;
            margin-top: 4px;
            padding: 8px;
            font-weight: 700;
            font-size: 1.4rem;
            cursor: pointer;

            &.active{
                background-color: $bleu;
            }
        }
    }
}


/*************************************************************************/
/********************************** A PROPOS DE NOUS *********************/
/*************************************************************************/
.node-type-a-propos-de-nous{

    .texte{
        padding-bottom: 40px;
        padding-top: 40px;
        font-size: 1.8rem;
        max-width: 800px;
        margin: 0 auto;

        h2{
            font-size: 1.333em;
            line-height: 28px;
            padding-bottom: 25px;
        }
        p{
            padding-bottom: 15px;
        }
        ul{
            @extend .flex;

            li{
                @extend .flex-item;
                //width: 20%;
                margin-right: 40px;
                padding: 63px 5px 0 5px;
                list-style-type: none;
                line-height: 21px;
                background-position: center top;
                background-repeat: no-repeat;

                &.engagement{
                    background-image: url('../images/picto-engagement.png');
                }
                &.methode{
                    background-image: url('../images/picto-methode.png');
                }
                &.outils{
                    background-image: url('../images/picto-outils.png');
                }
                &.atouts{
                    background-image: url('../images/picto_projet.png');
                }
                &:last-child{
                    margin-right: 0;
                }

                span{
                    font-family: $satisfy;
                    font-size: 1.467em;
                    padding-bottom: 13px;
                    display: inline-block;
                    color: $bleu;
                    width: 100%;
                }
            }
        }
    }
    .etape-projet{
        background-color: $blanc;
        padding: 40px 0 15px;

        h2{
            font-family: $satisfy;
            font-size: 2.133em;
            padding-bottom: 60px;
            text-align: center;

            span{
                color: $bleu;
            }
        }
        .row{
            max-width: 800px;
            margin: 0 auto;
            background-image: url('../images/separation-propos.png'), url('../images/apropos-fleche.svg');
            background-position: center top, center top;
            background-repeat: no-repeat, no-repeat;
            position: relative;

            &:nth-of-type(5){
                background-position: center top, center calc(100% - 44px);
            }

            .left{
                vertical-align: middle;
                text-transform: uppercase;
                color: $bleu;
                width: 50%;
                padding: 30px 0px 30px 60px;
                //border-right: 1px dashed #bab9b5;
                font-size: 1.2em;
                float: none;
                vertical-align: middle;

                p{
                    color: $bleu;
                }
            }
            .right{
                vertical-align: middle;
                width: 50%;
                padding: 30px 10px 30px 60px;
                line-height: 21px;
                float: none;

                p{
                    margin-bottom: 0;
                }
            }
            .num{
                position: absolute;
                left: 46.2%;
                top:-27px;
                color: $bleu;
                font-family: $satisfy;
                font-size: 1.533em;
                background-color: $blanc;
                border-radius:60px;
                border: 10px solid #d9d6d1;
                text-align: center;
                padding: 8px 0 4px;
                width: 58px;
            }
            &.vide{
                background-image: url('../images/separation-propos.png'), none;
                margin-bottom: 30px;

                .num{
                    visibility: hidden;
                }
            }
        }
    }
    .vous-souhaitez{
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        color: $blanc;

        .titre3{
            margin-bottom: 15px;
        }
        .btn{
            border-color: $blanc;
            margin: 20px 10px 0;
            width: 200px;

            &:hover{
                color: $bleu;
            }
        }
    }
}
.swiper-texte-icone{
    margin-top: -30px;

    .swiper-slide{
        background-color: $bleu;
        height: 270px;
        color: $blanc;
        text-align: center;
        padding: 40px 300px;

        p{
            font-size: 2rem;
            line-height: 2.8rem;
        }
        .titre3{
            margin: 10px 0;
        }
    }
    .swiper-pagination{
        bottom: 15px;
    }
    .swiper-pagination-bullet{
        background-color: transparent;
        border: 1px solid $blanc;
        opacity: 1;

        &.swiper-pagination-bullet-active{
            border-color: transparent;
            background-color: $blanc;
        }
    }
    .swiper-button-prev{
        background-image: url(../images/fleche-slide.png);
        left: 150px;
    }
    .swiper-button-next{
        background-image: url(../images/fleche-slide2.png);
        right: 150px;
    }
}

/*************************************************************************/
/********************************** CONTACT ******************************/
/*************************************************************************/
.webform-component--texte{
    float: left;
    text-align: center;
    color: $blanc;

    strong, h2{
        font-size: 1.867em;
        padding: 4px 0;
        display: inline-block;
        font-weight: 700;
    }
    h2{
        text-transform: uppercase;
    }
    a{
        color: $blanc;
        margin-top: 14px;
        padding-top: 14px;
        display: inline-block;
        background: url('../images/separation-contact.png') no-repeat center top transparent;
        width: 100%;
    }
}
.node-type-contact .carte{
    position: relative;

    .coordonnees-map{
        position: absolute;
        right: 5%;
        top: 60px;
        background: url('../images/picto_projet.png') no-repeat center 30px $blanc;
        width: 280px;
        height: 280px;
        padding: 80px 20px 20px;
        text-align: center;

        h2{
            font-family: $satisfy;
            font-size: 2.133em;
            color: $bleu;
            padding: 0 0 10px 0;
        }
        .adresse{
            padding: 50px 0 0 0;
            background: url('../images/separation-contact-gris.png') no-repeat center 23px transparent;
        }
        p{
            margin-bottom: 0;
        }
    }
}
.node-type-contact{
    .fd_titre{
        background: none $fdgris;
        margin-bottom: 0;

        .texte{
            font-size: 1.333em;
            padding-top: 40px;
            background: url('../images/separation-contact-gris.png') no-repeat center 15px transparent;
        }
    }
    .formulaire-contact{
        margin-top: 0px;
        display: table;
        width: 100%;
        background: linear-gradient(to right, $bleu 0%,$bleu 40%,#3fc0d5 40%,#3fc0d5 100%);
        position: relative;
        padding: 50px 0;

        .wrap{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    }
    .bloc-info{
        @extend .webform-component--texte;
        padding: 80px 0;
        height: auto;
        flex: 0 40%;

        p{
            margin-bottom: 0;
        }
    }
    #webform-ajax-wrapper-72{
        padding-bottom: 50px;
        flex: 0 60%;
    }
    .webform-client-form{
        padding-top: 40px;
        //padding: 70px 0 70px 50px;
        padding-left: 50px;
        position: relative;
        z-index: 2;
        display: table;
        width: 90%;

        &:after{
            content: '\e933';
            font-family: $projetim;
            font-size: 18rem;
            position: absolute;
            color: $bleu;
            right: -67px;
            top: -4px;
            z-index: -1;
        }
        fieldset{
            float: left;
            border: 0;
            width: 49%;
            min-height: 130px;
            margin-bottom: 15px;

            &:first-child{
                margin-right: 6px;
            }
        }
        input{
            width: 100%;
        }
        input[type="submit"]{
            width: auto;
            float: right;
            background-color: transparent;
            color: $blanc;
            text-transform: uppercase;
            cursor: pointer;
            margin-right: 6px;

            &:hover{
                background-color: $grisf;
                border-top: 1px solid $grisf;
                border-left: 1px solid $grisf;
            }
        }
        select{
            width: 100%;
            background-color: $grisf;
            border: 1px solid $grisf;
            color:$blanc;
        }
        textarea{
            //height: 127px;
            height: 162px;
        }
        .webform-component--champ-obligatoire{
            float: left;
            width: 50%;
            color:$blanc;
            font-size: 0.867em;
        }
    }
    .webform-confirmation{
        text-align: center;
        font-size: 2.5rem;
        font-weight: 700;
        p {
            color: #ffffff;
        }
    }
    .messages--error{
        padding-left: 50px;
    }
}


/*************************************************************************/
/********************************** BLOG SLIDER **************************/
/*************************************************************************/
.blog-slider{
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;

    .front &{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .section-les-actus-immobilieres &:before{
        display: none;
    }
    .titre4{
        padding: 0 10% 30px;
    }
    img{
        width: 100%;
        height: auto;
        //margin-bottom: 20px;

        .bottom &{
            margin-bottom: 20px;
        }
    }
    h3{
        font-size: 1.6rem;
    }
    .info{
        display: inline-block;
        padding-bottom: 20px;
        margin-bottom: 20px;
        width: 100%;
    }
    .barre-gris{
        bottom: 35px;
        left: 10%;
        width: 80%;
    }
    p{
        font-size: 1.4rem;
        color: $gris;
    }
    .swiper-wrapper{
        margin-bottom: 20px;
    }
    .swiper-button-next,
    .swiper-button-prev{
        width: 50px;
        height: 50px;
        background-image: none;
        top: unset;
        margin-top: 0;
        bottom: 35px;

        &:before{
            content: '\e920';
            font-family: $projetim;
            font-size: 4.5rem;
            display: inline-block;
            color: $bleu;
            position: relative;
            z-index: 10000;
        }
    }
    .swiper-button-prev{
        left: 37.5%;
    }
    .swiper-button-next{
        right: 37.5%;

        &:before{
            transform: scale(-1, -1);
        }
    }
}

/*************************************************************************/
/********************************** BLOG SLIDER LSTING *******************/
/*************************************************************************/
.section-les-actus-immobilieres{
    overflow-x: hidden;

    .content-page-basic{
        display: none;
    }
    .view-display-id-block_3{
        margin-top: 0;
    }
    .fd_titre{
        margin-bottom: 0;
    }
    .blog-slider{
        padding: 0;
        width: 100%;
        overflow: hidden;

        .wrap{
            width: 150%;
            max-width: 150%;
        }
        .content{
            background-color: $blanc;
            position: absolute;
            bottom: 0;
            width: 80%;
            left: 10%;
            opacity: 0;
            //transition: 0.3s;
            padding: 30px;

            h3 a{
                font-size: 3rem;
                margin-bottom: 10px;
                color: $gris;
            }
            p{
                font-size: 1.4rem;
                color: $gris5;
            }
            a{
                color: $gris5;

                &:hover{
                    text-decoration: none;
                }
            }
        }
        .info{
            margin-bottom: 0;
            padding-bottom: 0;
        }
        img{
            margin-bottom: 0;
             filter: brightness(70%);
        }
        .swiper-slide-active{
            .content{
                opacity: 1;
            }
        }
        .swiper-wrapper{
            margin-bottom: 0;
        }
    }
    .swiper-container:not(.swiper-push){
        width: 150%;
        left: -25%;
        background-color: $blanc;
    }
    .separateur{
        height: 60px;
        position: relative;
        z-index: 200;
    }
}
.blog-liste{
    padding-top: 40px;
    padding-bottom: 40px;

    .grid-item,
    .grid-sizer{
        width: 33.33%;
        border: 10px solid $fdgris;
        background-color: $blanc;
        list-style-type: none;
    }
    .content{
        padding: 10px;
        border:1px solid $gris4;
        text-align: center;
        position: relative;

        img{
            margin-bottom: 20px;
            width: 100%;
            object-fit: cover;
        }
        .barre-horizontale{
            width: 90%;
            left: 5%;
        }
        h2{
            font-size: 1.6rem;
            color: $gris;
            margin-bottom: 10px;

            a{
                color: $gris;
            }
        }
        a{
            color: $gris5;

            &:hover{
                text-decoration: none;
            }
        }
        p{
            margin-top: 23px;
            font-size: 1.4rem;
            color: $gris5;
        }
    }
}
.grid-item.bloc-social,
.bloc-social{
    background-color: $bleu;
    color: $blanc;
    padding: 55px 20px;
    text-align: center;

    ul{
        margin-bottom: 0;
    }
    li{
        display: inline-block;
        list-style-type: none;
        margin: 0 10px;

        a{
            color: $blanc;
            display: inline-block;
            font-size: 2.3rem;
            width: 40px;
            border: 3px solid $blanc;
            padding: 4px 0;
            border-radius: 100%;
        }
    }
    p{
        margin-bottom: 20px;
        text-transform: uppercase;

        span{
            display: block;
            text-transform: none;
            font-family: $satisfy;
            font-size: 4.4rem;
            line-height: 60px;
        }
    }
}


/*************************************************************************/
/********************************** BLOG DETAIL **************************/
/*************************************************************************/
.node-type-article {
    .fd_titre{
        padding-bottom: 230px;
    }
    main .flex{
        margin-top: -200px;
        position: relative;
        z-index: 10;
    }
}
.blog-content{
    @extend .flex-item;
    flex: 0 60%;
    position: relative;

    img{
        width: 100%;
        height: auto;
    }
    .categorie{
        color: $gris;
        margin-bottom: 15px;
        float: left;
    }
    .barre-horizontale{
        margin-bottom: 35px;
        position: relative;
        display: table;
    }
    .content{
        line-height: 2.2rem;
        text-align: justify;
        margin-bottom: 40px;
    }
}
.blog-aside{
    @extend .flex-item;
    flex: 0 35%;
    margin-left: 5%;
}
.view-liste-categories{
    ul{
        margin: 70px 0 0 0;
        border-bottom: 1px solid $fdgris;
    }
    li{
        list-style-type: none;
        width: 100%;
        border-top: 1px solid $fdgris;

        a{
            display: inline-block;
            width: 100%;
            padding: 12px;
            text-align: center;
            transition: 0.3s;

            &:hover{
                text-decoration: none;
                color: $bleu2;
            }
        }
    }
}
.btn-share{
    margin: -5px 0 0 0;
    float: right;

    li{
        display: inline-block;
        font-size: 1.5rem;
        margin: 0 3px;

        a{
            display: inline-block;
            color: $blanc;
            background-color: $bleu;
            border-radius: 100%;
            text-align: center;
            width: 28px;
            padding: 5px 0;
            transition: 0.3s;

            &:hover{
                background-color: $bleu2;
            }
        }
    }
}

.blog-5-last-post{
    background-color: $blanc;
    padding: 20px 20px 0 20px;
    margin-bottom: 70px;

    li{
        list-style-type: none;
        display: inline-block;
        width: 100%;
    }
    a{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        transition: 0.3s;

        &:hover{
            text-decoration: none;

            img{
                background-color: $fdgris;
            }
        }
    }
    img{
        border-radius: 100%;
        padding: 15px;
        border: 1px solid $gris4;
        flex: 0 80px;
        height: 80px;
        width: 80px;
        transition: 0.3s;
    }
    div{
        flex: 0 calc(100% - 127px);
        align-self: center;
    }
    p{
        margin-bottom: 0;
        line-height: 2.2rem;
    }
    .titre{
        color: $gris;
    }
    .categorie{
        color: $gris5;
        font-size: 1.4rem;
    }
}

/*************************************************************************/
/********************************** RECRUTEMENT **************************/
/*************************************************************************/
.node-type-recrutement{
    .fd_titre{
        margin-bottom: 0;
    }
}
.form-recrutement{
    padding: 50px 0;

    .messages--error{
        text-align: center;
        margin-bottom: 30px;
    }
    .titre3{
        text-align: center;
        color: $blanc;
    }
    .exergue{
        color: $blanc;
        text-align: center;
    }
    form{
        max-width: 600px;
        width: 95%;
        margin: 20px auto 0;

        p{
            @extend .flex;
            margin-bottom: 5px;
            color: $blanc;
        }
        .small{
            font-size: 1.3rem;
        }
        input,
        select,
        textarea{
            flex: 0 100%;
            border-color: $blanc;
        }
        .col2{
            input,
            select,
            textarea{
                flex: 0 49.5%;
            }
        }
        select{
            margin-bottom: 10px;
        }
        input[type="submit"]{
            flex: 0;
            margin: 0 auto;
            font-weight: 700;
        }
        input[name="jaime"],
        input[name="jaimepas"]{
            padding-left: 35px;
            background-repeat: no-repeat;
            background-image: url(../images/icones/selection3.svg);
            background-position: 10px center;
            background-size: 14px auto;
        }
        input[name="jaimepas"]{
            background-image: url(../images/icones/dislike.svg);
        }
        textarea{
            height: 120px;
        }
        input#cv{
            @extend .visuallyhidden;
        }
        label[for="cv"]{
            background-color: $blanc;
            color: $gris;
            width: 100%;
            padding: 5px;
            font-size: 1.5rem;
            border: 1px solid $blanc;
            margin-top: 10px;
        }
    }
    #webform-ajax-wrapper-514{
        text-align: center;
        color: $blanc;

        .links{
            a{
                @extend .btn-blanc;
                border-color: $blanc;

                &:hover{
                    color: $bleu;
                }
            }
        }
        .webform-confirmation{
            p{
                font-size: 2rem;
            }
        }
        .form-actions {
            margin-top: 20px;
        }
    }
}
.equipe{
    padding: 0 $marge 20px;
    background-color: $fdgris;
    margin-top: -20px;

    h2{
        text-align: center;
        @extend .titre3;
        padding: 40px 0;
    }
    ul{
        @extend .flex;

        &:after{
            content: '';
            flex: 0 33.33%;
        }
        li{
            flex: 0 33.33%;
            list-style-type: none;
            height: 375px;
            text-align: center;
            transition: 0.3s;
            padding: 30px;
            overflow: hidden;
            position: relative;

            &:before{
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                background-color: lighten($gris, 30%);
                border-radius: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            .titre3{
                font-size: 2.6rem;
                color: $bleu;
                transition: 0.3s;
            }
            a{
                font-weight: 700;
                margin-bottom: 10px;
                display: inline-block;
            }
            &.rejoindre a{
                margin-bottom: 0;
            }
            img{
                border-radius: 100%;
                opacity: 1;
                //clip-path: circle(60px at center);
            }
            .image{
                position: relative;
                transition: 0.3s;
                margin-bottom: 15px;
                z-index: 2;

                img:nth-child(2){
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 10;
                    transition: 0.3s;
                }
            }
            .fonction{
                margin-top: 10px;
                font-size: 1.4rem;
                position: relative;
                transition: 0.3s;
                padding: 0 70px;
            }
            .like{
                @extend .flex;
                height: 90px;
                background-image: url(../images/separation-membre.svg);
                background-position: center bottom;
                background-repeat: no-repeat;
                margin-top: -100px;
                opacity: 0;
                width: 100%;
                transition: 0.3s;
                position: absolute;
                top: 300px;
                left: 0;
                z-index: 1;
                color: $blanc;

                p{
                    flex: 0 50%;
                    padding: 20px;
                    font-size: 1.4rem;
                    font-style: italic;
                    margin-top: -20px;
                    color: $blanc;

                    &:first-child{
                        text-align: left;

                        &:before{
                            content: '\e939';
                            font-family: $projetim;
                            font-size: 2rem;
                            font-style: normal;
                            display: block;
                            padding-left: 40px;
                            margin-bottom: 10px;
                        }
                    }
                    &:last-child{
                        text-align: right;

                        &:before{
                            content: '\e925';
                            font-family: $projetim;
                            font-size: 2rem;
                            display: block;
                            font-style: normal;
                            padding-right: 40px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
            &:hover:not(.disable){
                background-color: $bleu;
                color: $blanc;

                .titre3{
                    color: $blanc;
                }
                .image{
                    img:nth-child(2){
                        opacity: 0;
                    }
                }
                .fonction{
                    transform: translateY(-100%);
                    opacity: 0;
                }
                .like{
                    transform: translateY(50px);
                    opacity: 1;
                }
            }
        }
    }
}

/*************************************************************************/
/********************************** MES SELECTIONS ***********************/
/*************************************************************************/
.page-node-73 .content-page h2{
    color: $bleu;
    font-family: $satisfy;
    font-size: 2.133em;
    text-transform: none;
    font-weight: normal;
}
.page-node-73 .content-page .btn{
    background-color: $bleu;
    border: 1px solid $bleu;
    color: $blanc;

    &:hover{
        text-decoration: none;
    }
}
.page-node-73 .text-bottom{
    background: url('../images/separation-contact-gris.png') no-repeat center top transparent;
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 1.333em;
}

.block-projetim-selections {
    position: relative;
    min-height: 100px;
}

/*************************************************************************/
/********************************** GALERIE ******************************/
/*************************************************************************/
.filtre-galerie{
    margin: 30px 0;

    .flex{
        max-width: 1000px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        p{
            font-family: $satisfy;
            font-size: 2.2rem;
            margin-right: 15px;
        }
        div{
            //text-transform: uppercase;
            margin: 0 2px;
        }
        select{
            height: auto;
        }
        .filter-bien{
            padding-top: 0;
            margin-left: 40px;
        }
    }
}
.view-galerie{
    .erreur{
        margin-bottom: 100px;
    }
    .btn-blanc{
        &:hover{
            color: $bleu;
        }
    }
    .show-more{
        margin: 50px 0;
        text-align: center;
    }
    .grid {
    @extend .wrap;
        //margin-bottom: 50px;
        display: table;

        .grid-content{
            display: table;
            //margin-bottom: 50px;
            width: 100%;
        }

        .grid-item--width1 {
            width: 68.65%;
        }
        .grid-item--width2 {
            width: 31.25%;
        }
        .grid-item--width3 {
            width: 50%;
        }
        .grid-item--width4 {
            width: 50%;
        }
        .grid-item--width5 {
            width: 25%;
        }
        .grid-item--width6 {
            width: 100%;
        }
        .bien5{
            margin-top: -25%;
        }
        .grid1{
            float: left;
            width: 25.25%;
        }
        .grid2{
            float: left;
            width: 74.75%;
        }
    }

    .grid-item {
        list-style-type: none;
        box-sizing: border-box;
        border: 10px solid $blanc;
        position: relative;
        float: left;


        img{
            width: 100%;
            transition: 0.3s;
            height: auto;
        }
        .overlay{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba($noir, 0.2);
            top: 0;
            left: 0;
            text-align: center;
            color: $blanc;
            opacity: 0;
            transition: 0.3s;
            padding: 0 20px;

            .center{
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                transform: translateY(-50%);
            }
            ul{
                padding-bottom: 22px;
                position: relative;
                margin-bottom: 25px;

                li{
                    display: inline-block;
                    list-style-type: none;
                    font-size: 3rem;
                    margin: 0 4px;
                }
            }
            .barre-horitzontale{
                background-color: $blanc;
                max-width: 200px;
                width: 70%;
                left: 50%;
                transform: translateX(-50%);
                top: 60px;

                &:after,
                &:before{
                    background-color: $blanc;
                }
            }
        }
        &:hover{
            img{
                filter: blur(2px);
            }
            .overlay{
                opacity: 1;
            }
        }
    }

    .pager a{
        @extend .btn;
        @extend .btn-gris;
        margin: 0 auto 50px;
        color: $gris;
    }
}




/*************************************************************************/
/********************************** ESPACE INVESTISSEUR ******************/
/*************************************************************************/
.investisseur{
    .bloc-2-col{
        .flex-item{
            flex: 0 48%;
            line-height: 2.1rem;

            &:nth-child(1){
                text-align: right;
                padding-left: 15%;
            }
            &:nth-child(2){
                padding-right: 15%;
            }
            img{
                width: 100%;
                height: auto;
            }
        }
    }
    .blog-slider,
    .calculateur-epargne,
    .bloc-2-col,
    .investisseur-promo{
        padding-top: 50px;
        padding-bottom: 50px;

    }
    .blog-slider:nth-of-type(2n+1){
        @extend .bg-gris;

        position: relative;

        &:after{
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 5px;
            content: '';
            display: inline-block;
            background-repeat: repeat-x;
            background-position: left bottom;
            background-image: url(../images/pattern-gris-bottom.svg);
        }

    }
    .blog-slider{
        h2{
            @extend .titre4;
        }
        .btn{
            //color: $gris;
        }
    }
}
.calculateur-epargne{
    text-align: center;
    padding-bottom: 0;

    .sstitre{
        margin-top: 25px;
        font-size: 1.9rem;
    }
    .btn:hover{
        color: $violet;
    }
}
.investisseur-promo{
    text-align: center;
}
.chart_investisseur{
    padding: 50px 0 0;
    background-image: url(../images/fleche-investissement.svg);
    background-repeat: no-repeat;
    background-position: calc(50% + 40px) 30px;
    //opacity: 0;
    .remplissez-mobile{
    	display: none;
    }

    .bulle{
        text-transform: none;
        background-color: $gris;
        padding: 5px;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        box-shadow: 0 0 3px rgba($noir, 0.2);
        font-size: 1.2rem;
        z-index: 10;
        width: 87%;
        display: none;
    }
    .flex-item{
        flex: 0 50%;
        position: relative;

        img{
            width: auto;
            height: auto;
        }
        &:nth-child(2){
            padding-left: 15%;
        }
        &:nth-child(3){
            padding-right: 15%;
        }
    }
    form{
        margin-top: 40px;
        margin-bottom: 40px;
    }
    label{
        background-color: rgba($blanc, 0.15);
        display: inline-block;
        padding: 5px 10px;
        width: calc(80% - 8px);
        text-align: left;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.4rem;
        margin-right: 4px;
        vertical-align: middle;
        position: relative;
        line-height: 2rem;
    }
    input{
        width: 20%;
        background-image: url(../images/euro.png);
        background-position: calc(100% - 5px) 6px;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
    }
    .icon-question2{
        color: $blanc;
        font-size: 1rem;
        background-color: $violet;
        display: inline-block;
        border-radius: 100%;
        width: 20px;
        padding: 5px 0;
        text-align: center;
        float: right;
        cursor: pointer;
    }
    h2{
        font-family: $satisfy;
        font-size: 2.2rem;
        line-height: 2.2rem;
    }
    p{
        margin-bottom: 4px;

        &:first-child{
            margin-bottom: 20px;
        }
        &.resultat{
            margin-top: 10px;

            label{
                background-color: darken($violet, 5%);
            }
            input{
                background-color: darken($violet, 5%);
                color: $blanc;
                border-color: darken($violet, 5%);
                background-position: calc(100% - 5px) -48px;
            }
        }
    }
    .piechart{
        float: left;
        margin-left: 87px;
        margin-top: 15px;
        position: relative;
        z-index: 2;
    }
    .btn{
        margin: 0 auto;
    }
    .link-action{
        margin-top: 84px;

        li{
            a{
                border-color: rgba($violet, 0.6);
                color: $gris4;
            }
            &.mail a{
                font-size: 1.4rem;
            }
        }
    }
    .piechart-empty{
        width: 225px;
        height: 225px;
        position: absolute;
        border-radius: 100%;
        color: $blanc;
        background-color: $violet3;
        left: 101px;
        top: 95px;
        z-index: 1;

        .icon-question2{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: transparent;
            font-size: 13rem;
            width: auto;
            color: $violet;
        }
    }
}

.resultat-calculateur{
    background-color: $violet3;
    color: $blanc;
    padding-top: 0;
    padding-bottom: 0;
    display: none;

    .flex{
        background-image: url(../images/fd-resultat-calculateur.svg), url(../images/fd-resultat-calculateur.svg);
        background-repeat: no-repeat;
        background-position: 14.5% -1px, 84% -1px;
    }
    .flex-item{
        flex: 0 32%;
        text-align: center;
        padding: 50px 0;
        position: relative;
        align-self: center;
    }
    .ligne-verticale{
        width: 1px;
        height: 100%;
        position: absolute;
        background-color: $blanc;
        left: 50%;
        top: 0;
        z-index: 1;

        &:before,
        &:after{
            position: absolute;
            left: -3.5px;
            width: 8px;
            height: 8px;
            background-color: $blanc;
            content: '';
            display: inline-block;
            border-radius: 100%;
        }
        &:before{
            top: 0;
        }
        &:after{
            bottom: 0;
        }
    }
    p{
        margin-bottom: 0;
        background-color: $violet3;
        padding: 20px;
        position: relative;
        z-index: 2;
        font-size: 1.8rem;
    }
    .nb-result{
        font-size: 4.7rem;
        color: $violet3;
        display: block;
        background-color: $blanc;
        border-radius: 100%;
        width: 82px;
        text-align: cener;
        padding: 22px 0;
        font-weight: 700;
        margin: 0 auto 15px;
        font-family: $asap;
    }
    &.une-colonne{
        .flex{
            background-image: url(../images/fd-resultat-calculateur.svg);
            background-repeat: no-repeat;
            background-position: 50% -1px;
        }
        .flex-item{
            flex: 0 100%;

            &.js-today{
                padding-bottom: 0;
            }
            &.js-invest-ancien,
            &.js-invest-neuf,
            &.js-no-result{
                padding-top: 0;
            }
        }
    }
}

.prise-rendez-vous-invest{
    background-color: $violet;
    color: $blanc;
    padding-bottom: 50px;
    text-align: center;
    margin-top: 60px;

    .icon-visite{
        font-size: 4.2rem;
        background-color: $bleu;
        display: inline-block;
        text-align: center;
        border: 20px solid $blanc;
        border-radius: 100%;
        width: 76px;
        color: $blanc;
        padding: 17px 0;
        margin-top: -60px;
        margin-bottom: 15px;
        position: relative;
    }
    .titre3{
        margin-bottom: 15px;
    }
    .flex-item{
        flex: 0 48%;

        &:nth-child(1){
            text-align: right;
        }
        &:nth-child(2){
            text-align: left;
        }
    }
    form{
        max-width: 600px;
        width: 90%;
        margin: 0 auto 10px auto;
        position: relative;

        &:after{
            content: '\e933';
            position: absolute;
            display: inline-block;
            color: rgba($blanc, 0.2);
            font-family: $projetim;
            top: 30px;
            right: -118px;
            font-size: 18rem;
        }

        .left,
        .right{
            width: 100%;
        }

        .row:nth-of-type(1){
            margin-bottom: 20px;

            .left,
            .right{
                width: 49.6%;
            }
            input:not([type="submit"]),
            select{
                width: 100%;
            }
        }

        input:not([type="submit"]),
        textarea{
            margin-bottom: 5px;
            width: 49.6%;
        }
        .js-success{
            font-size: 1.8rem;
        }
    }

    .g-recaptcha {
        margin: 20px 0;
    }
}


/*************************************************************************/
/********************************** PREMARKET ****************************/
/*************************************************************************/
.node-type-premarket{
    .fd_titre{
        margin-bottom: 0;

        &:after{
            display: none;
        }
    }
    .step{
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.3rem;
        border-bottom: 1px solid $jaune;
        color: $jaune;
        display: table;
        margin: 0 auto;
    }
}

.premarket-etat{
    color: $blanc;
    padding: 50px 0;
    text-align: center;

    .wrap{
        max-width: 800px;
        width: 100%;
    }
    .flex{
        align-items: center;
    }
    .nombre{
        display: block;
        width: 127px;
        height: 127px;
        border-radius: 100%;
        background-color: $blanc;
        color: $jaune;
        font-family: $asap;
        margin: 10px auto;
        text-transform: uppercase;
        font-size: 1.35rem;
        padding: 29px 0;

        span{
            font-weight: 700;
            font-size: 4rem;
            display: block;
        }
    }
    .flex-item:nth-child(1),
    .flex-item:nth-child(3){
        flex: 0 40%;
    }
    .flex-item:nth-child(1){
        font-family: $satisfy;
        font-size: 2.6rem;
    }
    .flex-item:nth-child(3){
        text-align: left;

        p{
            line-height: 2.1rem;

            &.exergue{
                line-height: 2.8rem;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
.premarket-step1{
    padding: 40px 0;

    &.formulaire-bien {
        .filtre{
            background-image: url(../images/fd-premarket-form.png);
            background-position: center -8px;
            padding-top: 36px;

            p{
                margin-bottom: 0;
            }
        }
    }
    .exergue{
        text-align: center;
        margin-top: 10px;
        margin-bottom: 0;
    }
}
.premarket-step2{
    margin-bottom: 50px;
    background-image: url(../images/premarket-fleche1.png);
    background-repeat: no-repeat;
    background-position: center -1px;

    &.vendre-bien{
        p:not(.required-field){
            &.step{
                border-bottom: 1px solid $blanc;
                color: $blanc;
                font-size: 1.3rem;
                margin-bottom: 20px;
                line-height: 1.6rem;
            }
            &.exergue{
                font-size: 2rem;
            }
        }
    }
    .btn{
        display: inline-block;
    }
    .btn-blanc:hover{
        color: $jaune;
    }
}
.premarket-step3{
    margin-top: -50px;
    margin-bottom: 50px;
    padding: 50px 0;
    background-image: url(../images/premarket-fleche2.png);
    background-repeat: no-repeat;
    background-position: center -1px;
    color: $blanc;
    text-align: center;

    .step{
        border-bottom: 1px solid $blanc;
        color: $blanc;
        margin-bottom: 20px;
    }
    .exergue{
        margin: 10px 0 30px;
    }
    form{
        margin-top: 20px;
        max-width: 560px;
        width: 100%;
        margin: 0 auto;
        position: relative;

        &:after{
            content: '\e933';
            font-family: $projetim;
            font-size: 20rem;
            position: absolute;
            top: 0;
            right: -140px;
            color: rgba($blanc, 0.4);
            z-index: 1;
        }

        input{
            width: 49%;
            border-color: $blanc;
            position: relative;
            z-index: 10;

            &[type="submit"]{
                width: auto;
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }
        textarea{
            width: 98.7%;
            border-color: $blanc;
            height: 120px;
            position: relative;
            z-index: 10;
        }
        p{
            margin-bottom: 5px;

            &.required-field{
                text-align: left;
            }
        }
    }
    .error-container{
        margin-top: 20px;
        font-size: 2rem;
    }
}


/*************************************************************************/
/********************************** POPUP RV SELECTIONS *******************/
/*************************************************************************/
.schedule{
    background-color: $blanc;
    position: relative;
    max-width: 933px;

    h2{
        font-family: $satisfy;
        color: $bleu;
        font-size: 2.133em;
        padding: 11px 0;
        display: inline-block;
        width: 100%;

        .left{
            float: left;
        }
        .right{
            float: right;
        }
    }
    .picto-calendar{
        position: absolute;
        top:-15px;
        left: 50%;
        margin: 0 -56px;
    }
    .bloc-biens{
        padding: 40px 55px 25px;
        background-color: $bleu;

        h3{
            color: $blanc;
            padding-bottom: 15px;

            span{
                float: right;
                font-size: 0.75em;
                margin-top: 8px;
            }
        }

        .left{
            width: 525px;
            float: left;
            margin-right: 50px;
        }

        li{
            list-style-type: none;
            margin-bottom: 3px;
            width: 100%;
            padding: 5px 10px;
            color: $gris;

            display: inline-block;

            input{
                float: right;
                margin-top: 3px;
            }
            span{
                float: left;
                width: 96%;
            }
        }
        // & .error li:first-child{
        //  border: 1px solid red;
        // }
        & .error:before{
            content: "selectionner au moins un bien";
            color: red;
        }
        .right{
            width: 248px;

            input, select{
                width: 100%;
                background-color: $grisf;
                border-color: $grisf;
                color: $blanc;
            }
        }
        .nb{
            color: $blanc;
            padding-top: 5px;
        }
    }
    .obligatoire{
        font-style: 0.833em;
        padding-top: 5px;
        color: $grisf;
    }
    .form{
        padding: 50px 55px 25px;
        background-color: $fdgris;

        .intro{
            margin-bottom: 5px;
        }
        h3{
            color: $grisf;
            padding-bottom: 15px;
        }
        fieldset{
            border: 0;

            &.bloc-input{
                width: 275px;
                margin-right: 10px;

                input, select{
                    width: 100%;
                }
            }
            &.bloc-textarea{
                width:535px;

                textarea{
                    width: 100%;
                    height: 112px;
                    margin-top: 33px;
                }
            }
            .ville, .bat{
                input{
                    width: 49.5%;
                    float: left;

                    &.first{
                        margin-right: 1%;
                    }
                }
            }
        }
        .buttons{
            margin: 0 auto 0;
            display: table;

            input[type="submit"]{
                text-align: center;
                background-color: $bleu;
                border-color: $bleu;
                cursor: pointer;
                color: $blanc;
                text-transform: uppercase;
                margin-top: 33px;

                &:hover{
                    background-color: $grisf;
                    border-color: $grisf;
                }
            }
        }
    }
}
.schedule-consultation .form fieldset.bloc-textarea{
    width: 253px;

    textarea{
        height: 114px;

    }
}
.confirmation{
    background-color: $fdgris;
    margin-top: 64px;
    position: relative;

    .action {
        display: table;
        margin: 0 auto;
    }
    .picto-calendar{
        position: absolute;
        top:-76px;
        left: 50%;
        margin: 0 -56px;
    }
    h2{
        font-family: $satisfy;
        color: $bleu;
        font-size: 2.133em;
        padding: 80px 0 20px;
        margin-bottom: 17px;
        text-align: center;
        background: url('../images/separation-popup.png') no-repeat center bottom transparent;
    }
    p{
        text-align: center;

        strong{
            font-size: 1.433em;
        }

        &.last{
            background: url('../images/separation-popup.png') no-repeat center bottom transparent;
            padding-bottom: 29px;
        }
    }
    .btn{
        text-align: center;
        background-color: $bleu;
        border-color: $bleu;
        cursor: pointer;
        color: $blanc;
        text-transform: uppercase;
        display: inline-block;
        margin: 21px 2px 30px;
        padding: 8px 61px;

        &:hover{
            background-color: $grisf;
            border-color: $grisf;
        }
    }
}
.fancybox-skin{
    border-radius:0;
    background-color: $blanc;
}
.fancybox-close {
    background-image: none;
    color: $blanc;
    font-family: $asap;
    font-size: 0.833em;
    right: 5px;
}
a.fancybox-dpe {
    &:hover {
        text-decoration: underline;
    }
}



/*************************************************************************/
/********************************** POPUP ********************************/
/*************************************************************************/
.popup{
    background-color: rgba($blanc, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100000000;
    top: 0;
    left: 0;

    .popup-content{
        max-width: 510px;
        width: 90%;
        background-color: $fdgris;
        border: 10px solid $blanc;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 40px;
        text-align: center;
        box-shadow: 0 0 15px rgba($noir, 0.3);

        p{
            font-size: 2rem;
            margin: 10px 0;
        }
        i{
            color: $bleu;
            font-size: 4rem;
            margin-bottom: 20px;
            display: inline-block;
        }
        .fermer{
            text-transform: uppercase;
            position: absolute;
            right:  10px;
            top: 10px;
            font-size: 1.3rem;
        }
    }
}

/*************************************************************************/
/********************************** INSTA FEED ***************************/
/*************************************************************************/
.instafeed{
    overflow: hidden;
    height: 0;
    transition: 0.2s;

    a{
        width: calc(100% / 7);
        height: auto;
        //padding-bottom: 14.28%;
        display: block;
        position: relative;
        color: $blanc;
        overflow: hidden;
        float: left;
        //background-size: cover;
        //background-color: $bleu;
        background-repeat: no-repeat;
        background-position: center center;
        transition: 0.2s;

        img{
            width: 100%;
            height: auto;
            transition: 0.5s;
            //visibility: hidden;
        }
        .overlay{
            background-color: rgba($noir, 0.6);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            opacity: 0;
            transition: 0.3s;

            p{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            span{
                font-size: 2.3rem;

                &:before{
                    font-family: $fa;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 5px;
                }
                &.likes{
                    //margin-right: 40px;
                }
                &.likes:before{
                    content: '\f08a';
                }
                &.comments:before{
                    content: '\f0e5';
                    transform: scaleX(-1);
                    margin-top: -5px;
                }
                &.caption{
                    display: block;
                    margin-top: 20px;
                    font-size: 1.3rem;
                    line-height: 2rem;
                }
            }
        }
        &:hover{

            .overlay{
                opacity: 1;
            }
            img{
                transform: scale(1.1, 1.1);
            }
        }
    }
}
.mobile .instafeed{
    padding-bottom: 33.33%;

    a{
        width: calc(100% / 3);
        //padding-bottom: 33.33%;
    }
}
.tabletV .instafeed{
    padding-bottom: 25%;

    a{
        width: calc(100% / 4);
        //padding-bottom: 25%;
    }
}
.tabletH .instafeed{
    padding-bottom: 20%;

    a{
        width: calc(100% / 5);
        //padding-bottom: 20%;
    }
}
.desktop .instafeed{
    padding-bottom: 16.66%;

    a{
        width: calc(100% / 6);
        //padding-bottom: 16.66%;
    }
}
.desktop-l .instafeed{
    padding-bottom: 14.28%;

    a{
        width: calc(100% / 7);
        //padding-bottom: 14.28%;
    }
}
.desktop-xl .instafeed{
    padding-bottom: 12.5%;

    a{
        width: calc(100% / 8);
        //padding-bottom: 12.5%;
    }
}

/*************************************************************************/
/********************************** RESEAUX SOCIAUX **********************/
/*************************************************************************/
.reseaux{
    background-color: $blanc;
    display: table;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 25px;
    margin-top: 10px;

    p,
    ul{
        display: inline-block;
        vertical-align: middle;
    }
    ul{
        margin: 0 5px;
    }
    p{
        text-transform: uppercase;
        font-size: 0.933em;
        width: 200px;

        &:first-child{
            text-align: right;
        }
        &:last-child{
            text-align: left;
        }
    }
    li{
        list-style-type: none;
        margin: 0 5px;
        float: left;

        a{
            font-size: 2.3rem;
            color: $bleu;
            float: left;
            display: inline-block;
            border: 3px solid $bleu;
            border-radius:40px;
            width: 41px;
            text-align: center;
            transition: 0.5s;
            padding: 4px 0;

            &:hover{
                background-color: $bleu;
                color: $blanc;
            }
        }
        &.mail a{
            background-color: $bleu;
            color: $blanc;
        }
    }
    .text-mail{
        cursor: pointer;
    }
}

/*************************************************************************/
/********************************** MAIL CHIMP ***************************/
/*************************************************************************/
.newsletter{
    @extend .reseaux;
    margin-top: 0;
    padding: 30px 0;
    display: none;

    .form-item{
        float: left;

        input{
            margin: 0 15px 0 5px;
        }
    }
    input[type="submit"]{
        background-color: $bleu;
        border-color: $bleu;
        color: $blanc;
        cursor: pointer;
        padding: 5px 25px;

        &:hover{
            background-color: $grisf;
            border-color: $grisf;
        }
    }
    .mailchimp-newsletter-wrapper{
        display: none;
    }
    p{
        text-align: center;
        width: auto;
        padding: 25px;
        color: $bleu;
    }
    p.inscription-ok{
        padding: 0;
    }
}

/*************************************************************************/
/********************************** FOOTER *******************************/
/*************************************************************************/
footer{
    background-color: $fdgris;
    padding: 40px 0;

    .block-menu{
        display: inline-block;
        width: calc((100% - 50px) / 5);
        vertical-align: top;
        padding: 0 30px;

        &:last-child{
            h2{
                display: none;
            }
        }
        h2{
            @extend .titre4;
            font-size: 1.4rem;
        }
        a{
            color: $gris3;
            transition: 0.2s;

            &:hover{
                color: lighten($gris3, 30%);
            }
        }
        li{
            list-style-type: none;
            line-height: 2rem;
        }
    }
    form[name=search-reference]{
        margin-top: 10px;

        input[type="text"]{
            width: 77%;
        }
        input[type="submit"]{
            padding: 0 10px;
            height: 24px;
        }
        input{
            height: 25px;
            font-size: 1.2rem;
        }
    }
}
.info-footer{
    padding: 15px $marge;
    background-color: $gris3;
    color: $blanc;
    font-size: 1.4rem;
    text-align: center;
}


/*************************************************************************/
/********************************** USER LOGIN ***************************/
/*************************************************************************/
.user-login{
    .description{
        display: none;
    }
    .wrap{
        margin: 50px auto;
        width: 100%;
        max-width: 700px;
    }
    .form-item{
        margin-bottom: 20px;
    }
    label{
        width: 20%;
        display: inline-block;
    }
    input{
        width: 75%;
    }
    #edit-actions{
        text-align: center;
    }
}

/*************************************************************************/
/********************************** 404 **********************************/
/*************************************************************************/
.node-type-404{
    .content-404{
        background-image: url(../images/fd-404.jpg);
        background-position: center center;
        background-size: cover;
        margin-top: -50px;
        height: calc(100vh - 152px);
    }
    .wrap{
        height: 100%;

        div{
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            .titre2{
                color: $blanc;
                font-size: 4.5rem;
                line-height: 5.4rem;
                margin-bottom: 10px;
            }
            .titre3{
                font-size: 3.2rem;
                line-height: 3.6rem;
                margin-bottom: 40px;
                color: $gris3;
            }
            p{
                margin-bottom: 15px;
            }
            .btn{
                margin-top: 0;
            }
            .btn-blanc{
                margin-left: 35px;
                background-color: $blanc;
                color: $gris3;
                padding-left: 10px;
                height: 34px;

                i{
                    font-size: 1.8rem;
                    margin-right: 5px;
                    color: $bleu;
                    display: inline-block;
                    vertical-align: middle;
                }
                &:hover{
                    color: $blanc;

                    i{
                        color: $blanc;
                    }

                    &:before{
                        border-bottom-color: #01BACE;
                    }
                }
            }
        }
    }
    .reseaux,
    .instafeed,
    footer{
        display: none;
    }
}

/*************************************************************************/
/********************************** SITEMAP ******************************/
/*************************************************************************/
.sitemap-bloc{
    margin-bottom: 100px;

    h2{
        @extend .titre4;
    }
    ul{
        @extend .flex;
        justify-content: left;

        li{
            line-height: 2.4rem;
            @extend .flex-item;
            flex: 0 33%;
            padding-right: 20px;
        }
        a{
            transition: 0.2s;

            &:before{
                content: '- ';
            }
            &:hover{
                color: $bleu;
            }
        }
    }
    h3 a{
        text-transform: uppercase;
        color: $bleu;
        font-weight: 700;
    }
}

/*************************************************************************/
/********************************** PAGE DE BASE *************************/
/*************************************************************************/
.content-page-basic{
    line-height: 2.2rem;
    padding: 20px 0;

    h2{
        @extend .titre4;
        color: $bleu;

    }
    p + h2{
        margin-top: 50px;
    }
    ul{
        margin-left: 20px;
    }
    h3{
        @extend .titre6;
        margin-bottom: 20px;
    }
    p + h3{
        margin-top: 40px;
    }
    p:last-child{
        margin-bottom: 50px;
    }
    .flex-item{
        h2{
            text-align: center;

            &:before{
                content:'';
                display: block;
                font-family: $projetim;
                font-weight: 400;
                font-size: 4.5rem;
                margin-bottom: 20px;
            }
            &.mission-transaction:before{
                content:'\e904';
            }
            &.mission-location:before{
                content:'\e911';
            }
        }
        p{
            text-align: center;
            margin-bottom: 0px;

            &.small{
                font-size: 1.3rem;
            }
        }
    }
    table{
        margin: 30px auto;
        border-collapse: separate;
        border-spacing: 4px;
        max-width: 400px;
        width: 100%;

        th{
            background-color: $bleu;
            color: $blanc;
            text-align: left;
            padding: 8px 10px;
            font-weight: 400;
        }
        td{
            background-color: $fdgris;
            padding: 8px 10px;
        }
    }
}

/*************************************************************************/
/********************************** LOADER LISTE BIENS *******************/
/*************************************************************************/
.node-type-liste-biens main {
    position: relative;
}
.js-loader-full {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    background: rgba(255, 255, 255, 0.8);
    i {
        margin-top: 100px;
        margin-left: 50%;
        color: $bleu;
    }
}
.node-type-mes-biens .js-loader-full,
.page-node-73 .js-loader-full{
    i {
        position: absolute;
        margin-left: 0;
        margin-top: 0;
        top: 44.3%;
        left: 47.6%;
    }
}

.node-type-espace-investisseur .js-loader-full {
    background: none;

    i {
        margin-left: 0;
    }
}


// form visit
.success-bleu {
    color: $bleu;
    font-weight: bold;
    font-size: 1.200em;
}

/*************************************************************************/
/********************************** DATE PICKER **************************/
/*************************************************************************/
#ui-id-1{
    border: 0;
    width: 164px !important;

    li{
        color: $grisf;
        font-size: 0.733em;
        border: 0;

        &.ui-state-focus{
            background-color: $fdgris;
            border-color: $fdgris;
            border: 0;
            font-weight: normal;
        }
    }
    .ville{
        display: inline-block;
        width: 104px;
    }
}
#ui-datepicker-div{
    font-size: 0.933em;
    font-family: $asap;
    border: 1px solid $grisf;
    border-radius:0;
    background-color: $blanc;

    .ui-widget-header{
        background: none transparent;
        color: #4a5358;
        border: 0;
    }
    td{
        border:1px solid #d8d8d8;
        width: 20px;
        height: 25px;
        vertical-align: middle;
        text-align: center;

        a{
            background: none transparent;
            text-align: center;
            color: #4a5358;
            font-weight: 300;
            border: 0;
        }
        &.ui-state-disabled{
            background-color: #e5e5e5;

            &:hover{
                background-color: transparent;
            }
            .ui-state-default{
                background-image: none;
                border: 0;
                text-align: center;
                font-weight: 300;
                background-color: transparent;
                color: #a9a9a9;

                &:hover{
                    background-color: transparent;
                }
            }
        }
        &:hover,
        &.ui-datepicker-current-day{
            background-color: $bleu;
        }
        &.ui-datepicker-today{
            background-color: #acebf5;
        }
    }
    .ui-widget-header .ui-icon{
        background-image: url('../images/fleche-calendar.png');
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .ui-icon-circle-triangle-w{
        background-position: left top;
    }
    .ui-icon-circle-triangle-e{
        background-position: right top;
    }
    .ui-state-hover{
        background-color: transparent;
        border: 0px solid transparent;
    }
    th{
        font-weight: 300;
    }
}
